import React from 'react'
type SpanColumnNameProps = {
    children: string;
  };
 const SpanColumnName = ( {children}: SpanColumnNameProps) => {
  return (
   <span className="lg:hidden font-semibold">{children}: &nbsp;</span>
  )
}

export default SpanColumnName