import moment from "moment";
import useFetchDocument from "../../hooks/useFetchDocument";
import { Category, Post, Profile } from "../../types";
import { Subscription } from "../../types/subscription.type";
import useFetchProfilePosts from "../../hooks/useProfilePosts";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import SpanColumnName from "../../ui/SpanColumnName";
import ManualProfileReviewStatus from "../profiles/ManualProfileReviewStatus";

interface SubscriptionListItemProps {
  subscription: Subscription;
  categories: Category[];
}

const SubscriptionListItem = ({
  categories,
  subscription,
}: SubscriptionListItemProps) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const fetchProfile = useFetchDocument<Profile>({
    collection: "profiles",
    id: subscription.userId,
  });

  useEffect(() => {
    if (fetchProfile.data) {
      setProfile(fetchProfile.data);
    }
  }, [fetchProfile.data]);

  const { posts } = useFetchProfilePosts(profile?.id);

  if (fetchProfile.error) return <div>{fetchProfile.error.message}</div>;

  if (!profile) return <div>{subscription.id}</div>;

  const approvedPosts = posts.filter((post) => post.status === "approved");

  return (
    <div className="grid md:grid-cols-2 p-4 border-b border-gray-200 w-full">
      <div>
        <div className="text-lg font-semibold">
          {profile.name} {profile.lastName}
        </div>
        <div className="text-sm text-gray-500">
          <ManualProfileReviewStatus userId={profile.id} />
        </div>
        <div className="text-sm text-gray-500">Email: {profile.email}</div>
        <div className="text-sm text-gray-500">
          Phone: {profile.phoneNumber}
        </div>
        <div className="text-sm text-gray-500">
          Registered at: {moment(profile.createdAt).toLocaleString()}
        </div>
      </div>
      <div>
        <div className="md:text-lg font-semibold">
          {subscription.productId.includes("monthly")
            ? "Monthly subscription"
            : "Yearly subscription"}
        </div>
        <div className="text-sm text-gray-500">
          Provider: {subscription.provider}
        </div>
        <div className="text-sm text-gray-500">
          Expires at: {moment(subscription.expiresAt).toLocaleString()}
        </div>
        <div className="text-sm text-gray-500">
          Subscribed at: {moment(subscription.createdAt).toLocaleString()}
        </div>
      </div>
      <div className="mt-2 md:col-span-2">
        <div className="text-lg font-semibold">Posts</div>
        {approvedPosts.length === 0 && (
          <div className="alert alert-error text-sm my-4">
            No approved posts yet!
          </div>
        )}
        <div className="mt-2">
          <table className="table table-compact w-full">
            <thead>
              <tr className="hidden lg:table-row">
                <th>Name</th>
                <th>Category</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post: Post) => {
                const category = categories.find(
                  (category) => category.id === post.categoryId
                );

                return (
                  <tr
                    key={post.id}
                    className="flex flex-col lg:table-row w-full"
                  >
                    <td>
                      <SpanColumnName>Business name</SpanColumnName>
                      {post.businessName}
                    </td>
                    <td>
                      <SpanColumnName>Category</SpanColumnName>
                      {category?.name}
                    </td>
                    <td>
                      <SpanColumnName>Status</SpanColumnName>
                      {post.status}
                    </td>
                    <td>
                      <Link to={routeNames["posts.show"](post.id)}>
                        <button className="btn btn-primary btn-sm mr-2">
                          Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionListItem;
