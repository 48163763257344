import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

const COLLECTION_NAME = "admins";

const existsById = async (adminId: string) => {
  const docRef = doc(db, COLLECTION_NAME, adminId);
  const docSnap = await getDoc(docRef);

  return docSnap.exists();
};

export const AdminService = { existsById };
