import { Link } from "react-router-dom";
import { PageHeader } from "../../components/shared";
import { routeNames } from "../../routes";
import { PromotionalBannersList } from "../../components/lists/PromotionalBannersList";

export const PromotionalBannerIndexPage = () => {
  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>Promotional Banners</PageHeader>

        <Link to={routeNames["promotionalBanners.new"]}>
          <button className="btn btn-xs lg:btn-md">Create banner</button>
        </Link>
      </div>
      <div className="mt-8">
        <PromotionalBannersList />
      </div>
    </>
  );
};
