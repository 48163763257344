import { PageHeader } from "../../../components/shared";
import { Link, useParams } from "react-router-dom";
import { ReferralProgramProfile } from "../../../types";
import { routeNames } from "../../../routes";
import useReferralProgramProfile from "../../../hooks/useFetchReferralProgramProfile";
import { FaBan } from "react-icons/fa";
import { ReferralProgramProfileService } from "../../../services/referralProgramProfile.service";
import { ReferralProgramReferralsTable } from "../../../components/programs/ReferralProgramReferralsTable";
import { ReferralProgramProfilePhoneNumber } from "../../../components/programs/ReferralProgramProfilePhoneNumber";

export const ReferralDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const snapshot = useReferralProgramProfile(id!);

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error: {snapshot.error?.message}</div>;
  }

  const referralProgramProfile = snapshot.data as ReferralProgramProfile;

  const banUser = async () => {
    await ReferralProgramProfileService.ban(referralProgramProfile.id);
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>
          <div>
            {`${referralProgramProfile.name} ${referralProgramProfile.lastName}`}
            {referralProgramProfile.isBanned && (
              <span className="text-red-500 ml-2 text-sm">(Banned)</span>
            )}
          </div>
          <div className="text-sm text-gray-500">
            {referralProgramProfile.email}
          </div>
          <div className="text-sm text-gray-500">
            <ReferralProgramProfilePhoneNumber
              referralProgramProfile={referralProgramProfile}
            />
          </div>
        </PageHeader>
        <div className="flex items-center gap-2">
          {referralProgramProfile.isBanned !== true && (
            <button className="btn btn-xs lg:btn-md btn-dark" onClick={banUser}>
              <FaBan className="text-red-500" />
              <span className="ml-2">Ban this user</span>
            </button>
          )}

          <Link to={routeNames["programs.index.referral"]}>
            <button className="btn btn-xs lg:btn-md btn-info">
              Back to index
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-8">
        <ReferralProgramReferralsTable referrerId={referralProgramProfile.id} />
      </div>
    </>
  );
};
