import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import {
    AiRoutes,
  CategoryRoutes,
  GiftCardRequestRoutes,
  KnockRoutes,
  PhoneVerificationRoutes,
  PostRoutes,
  ProgramsRoutes,
  PromotionalBannerRoutes,
  SubscriptionRoutes,
} from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ManualProfileReviewRoutes } from "./routes/ManualProfileReviewRoutes";

function App() {
  return (
    <div className="min-h-screen">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {CategoryRoutes}
          {CategoryRoutes}
          {GiftCardRequestRoutes}
          {AiRoutes}
          {KnockRoutes}
          {ManualProfileReviewRoutes}
          {PhoneVerificationRoutes}
          {PostRoutes}
          {ProgramsRoutes}
          {PromotionalBannerRoutes}
          {SubscriptionRoutes}
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
