import { useEffect, useState } from "react";
import { ServiceRequestService } from "../../../services";
import { ServiceRequest } from "../../../types";
import { ServiceRequestListItem } from "../ServiceRequestListItem";

type ServiceRequestListParams = {
  knockId: string;
};

export const ServiceRequestList = ({ knockId }: ServiceRequestListParams) => {
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>();

  useEffect(() => {
    const unsubscribe = ServiceRequestService.getAllByKnockIdSuscription({
      onDataChange: (serviceRequests: ServiceRequest[]) => {
        setServiceRequests(
          serviceRequests.sort((a, b) => {
            return a.status.localeCompare(b.status);
          })
        );
      },
      knockId: knockId,
    });

    return () => {
      unsubscribe();
    };
  }, [knockId]);

  if (serviceRequests === undefined) {
    return <>Loading...</>;
  }

  return (
    <>
      <div className="lg:overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr className="flex w-full lg:table-row">
              <th className="flex-1">Business</th>
              <th className="hidden lg:table-cell">Contact</th>
              <th>
                <span className="hidden lg:table-cell">Status</span>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {serviceRequests!.map((serviceRequest: ServiceRequest) => {
              return (
                <ServiceRequestListItem
                  key={`service-request-${serviceRequest.id}`}
                  serviceRequest={serviceRequest}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
