import { PageHeader } from "../../components/shared";
import { SubscriptionCounters } from "../../components/subscriptions/SubscriptionCounters";
import SubscriptionList from "../../components/subscriptions/SubscriptionList";

export const SubscriptionsIndexPage = () => {
  return (
    <>
      <PageHeader>Subscriptions</PageHeader>
      <div className="mt-8">
        <SubscriptionCounters />
      </div>

      <div className="mt-8">
        <SubscriptionList />
      </div>
    </>
  );
};
