import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";

const useReferralProfilesCount = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [confirmedMobile, setConfirmedMobile] = useState(0);
  const [giftCardCount, setGiftCardCound] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCounts = async () => {
      setLoading(true);
      const referralProfilesRef = collection(db, "referralProgramProfiles");

      // All profiles
      const allDocsSnapshot = await getDocs(referralProfilesRef);
      setTotalCount(allDocsSnapshot.size);

      // Only confirmed profiles
      const confirmedQuery = query(
        referralProfilesRef,
        where("isConfirmed", "==", true)
      );
      const confirmedDocsSnapshot = await getDocs(confirmedQuery);
      setConfirmedCount(confirmedDocsSnapshot.size);

      // Only confirmed profiles with valid mobile numbers
      const confirmedMobileQuery = query(
        referralProfilesRef,
        where("isPhoneNumberValid", "==", true)
      );
      const confirmedMobileSnapshot = await getDocs(confirmedMobileQuery);
      setConfirmedMobile(confirmedMobileSnapshot.size);

      // All gift cards
      const giftCardsRef = collection(db, "giftCards");

      const allGiftCardsSnapshot = await getDocs(giftCardsRef);
      setGiftCardCound(allGiftCardsSnapshot.size);

      setLoading(false);
    };

    fetchCounts();
  }, []);

  return {
    confirmedCount,
    confirmedMobile,
    giftCardCount,
    loading,
    totalCount,
  };
};

export default useReferralProfilesCount;
