import useToggle from "../../hooks/useToggle";
import { ReferralProgramProfile } from "../../types";
import { Modal } from "../shared/Modal/Modal";
import { PhoneNumberValidationTag } from "./PhoneNumberValidationTag";
import { PhoneVerificationDetails } from "./PhoneVerificationDetails";

interface ReferralProgramProfilePhoneNumberProps {
  isPill?: boolean;
  referralProgramProfile: ReferralProgramProfile;
}

export const ReferralProgramProfilePhoneNumber = ({
  isPill,
  referralProgramProfile,
}: ReferralProgramProfilePhoneNumberProps) => {
  const [showModal, setShowModal] = useToggle(false);

  return (
    <>
      <Modal
        show={showModal}
        onCloseModal={() => setShowModal.set(false)}
        title="Phone verification"
      >
        {showModal && (
          <PhoneVerificationDetails
            phoneNumber={referralProgramProfile.phoneNumber!}
          />
        )}
      </Modal>
      <div className="flex items-center gap-1">
        <span
          className="underline cursor-pointer"
          onClick={() => setShowModal.set(!showModal)}
        >
          {referralProgramProfile.phoneNumber}
        </span>
        <PhoneNumberValidationTag
          referralProgramProfile={referralProgramProfile}
          isPill={isPill}
        />
      </div>
    </>
  );
};
