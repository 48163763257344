import { Route } from "react-router-dom";
import { PostIndexPage, PostShowPage } from "../pages/posts";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";

export const PostRoutes = (
  <>
    <Route
      path={routeNames["posts.index.pending"]}
      element={
        <ProtectedRoute>
          <PostIndexPage status="pending" title="Pending posts" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["posts.index.approved"]}
      element={
        <ProtectedRoute>
          <PostIndexPage status="approved" title="Approved posts" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["posts.index.rejected"]}
      element={
        <ProtectedRoute>
          <PostIndexPage status="rejected" title="Rejected posts" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["posts.index.blocked"]}
      element={
        <ProtectedRoute>
          <PostIndexPage status="blocked" title="Blocked posts" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["posts.show"](":id")}
      element={
        <ProtectedRoute>
          <PostShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
