import { useState } from "react";

function useToggle(init = false): [
  boolean,
  {
    set: (flag: boolean) => void;
    toggle: (e?: { preventDefault: () => void }) => void;
  }
] {
  const [value, setValue] = useState(init);
  return [
    value,
    {
      set: setValue,
      toggle: (e) => {
        if (e) {
          e.preventDefault();
        }
        setValue((flag) => !flag);
      },
    },
  ];
}
export default useToggle;
