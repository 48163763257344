import { doc, DocumentReference } from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreDocData } from "reactfire";

interface useFetchDocumentProps {
  id: string;
  collection: string;
}

const useFetchDocument = <T>({ id, collection }: useFetchDocumentProps) => {
  const docRef = doc(db, collection, id) as DocumentReference<T>;
  return useFirestoreDocData<T>(docRef);
};

export default useFetchDocument;
