import { Link, useParams } from "react-router-dom";
import { useCategoryQuery } from "../../queries/categories.queries";
import { routeNames } from "../../routes";

export const CategoryPage = () => {
  const categoryId = useParams()["id"];

  const categoryQuery = useCategoryQuery(categoryId!, false);

  if (categoryQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const category = categoryQuery.data;

  return (
    <>
      This is category page <Link to={routeNames["categories.index"]}>Ir </Link>
      <img src={category?.coverUrl} alt={`Cover for ${category?.name}`} />
    </>
  );
};
