import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
};

type MapProps = {
  lat: number;
  lng: number;
  height?: number;
};

export const Map = ({ height = 400, lat, lng }: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, height: `${height}px` }}
      center={{ lat, lng }}
      zoom={15}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  ) : (
    <></>
  );
};
