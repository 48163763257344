import { useNavigate } from "react-router-dom";
import { CategoryForm } from "../../../components/forms";
import { PageHeader } from "../../../components/shared";
import { routeNames } from "../../../routes";
import { CategoriesService } from "../../../services/categories.service";

export const CategoryNewPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader>New category</PageHeader>
      <div className="mt-8">
        <CategoryForm
          onSubmit={async (formData) => {
            await CategoriesService.create({
              active: formData.active,
              coverUrl: formData.coverUrl,
              isFeatured: formData.isFeatured,
              name: formData.name,
            });

            navigate(routeNames["categories.index"]);
          }}
        />
      </div>
    </>
  );
};
