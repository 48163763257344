import { useEffect, useRef, useState } from "react";
import { Chat, ChatMessage } from "../../types";
import { ChatMessageService } from "../../services/chatMessage.service";
import { Map } from "../shared";
import DateTimeDisplay from "../../ui/dateTimeDisplay";
import TimeAgo from "timeago-react";

type ChatMessagesListParams = {
  chat: Chat;
};

export const ChatMessagesList = ({ chat }: ChatMessagesListParams) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessage[]>();

  useEffect(() => {
    const unsubscribe = ChatMessageService.getAllSuscription({
      chatId: chat.id,
      onDataChange: setMessages,
    });

    return () => unsubscribe();
  }, [chat]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="">
      {messages === undefined ? (
        <div>Loading messages...</div>
      ) : (
        <>
          <div className="max-h-96 overflow-auto">
            {messages!.map((message: ChatMessage) => {
              const isFromProvider = chat.providerId === message.userId;

              return (
                <div key={`message-${message.id}`} className="grid grid-cols-2">
                  <div
                    className={`bg-light-gray p-1 rounded my-2 ${
                      isFromProvider ? "col-start-2" : null
                    }`}
                  >
                    <div className="font-bold text-sm">
                      {isFromProvider ? "Provider" : "Customer"}
                    </div>
                    <div>
                      {message.content !== "" ? (
                        message.content
                      ) : (
                        <i>{message.event}</i>
                      )}
                    </div>
                    {message?.imageUrls?.length > 0 && (
                      <div>
                        <img src={message.imageUrls[0]} alt="image_chat" />
                      </div>
                    )}
                    {message.location && (
                      <div>
                        <Map
                          height={180}
                          lat={message.location.lat}
                          lng={message.location.lng}
                        />
                      </div>
                    )}
                    <div className="text-right text-xs flex flex-col">
                      <DateTimeDisplay date={message.createdAt} />
                      <TimeAgo datetime={message.createdAt} />
                    </div>
                  </div>
                  <div ref={bottomRef} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
