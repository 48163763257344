import { useNavigate } from "react-router-dom";
import { PromotionalBannerForm } from "../../components/forms";
import { PageHeader } from "../../components/shared";
import { routeNames } from "../../routes";
import { PromotionalBannerService } from "../../services/promotionalBanner.service";

export const PromotionalBannerNewPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader>New Banner</PageHeader>
      <div className="mt-8">
        <PromotionalBannerForm
          onSubmit={async (formData) => {
            await PromotionalBannerService.create({
              coverUrlEn: formData.coverUrlEn,
              coverUrlEs: formData.coverUrlEs,
              isActive: formData.isActive,
              name: formData.name,
              url: formData.url,
            });

            navigate(routeNames["promotionalBanners.index"]);
          }}
        />
      </div>
    </>
  );
};
