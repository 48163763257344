import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { AdminService } from "./admin.service";
import { firebaseConfig } from "./firebaseConfig";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const callFirebaseFunction = (functionName: string, data: any) => {
  return httpsCallable(functions, functionName)(data);
};

const logInWithEmailAndPassword = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password).then(
    async (user: UserCredential) => {
      const isAdmin = await AdminService.existsById(user.user.uid);

      if (isAdmin === false) {
        logout();
        throw new Error("auth/not-an-admin");
      }
    }
  );
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  storage,
  callFirebaseFunction,
  logInWithEmailAndPassword,
  logout,
};
