import { ReferralEditForm } from "../../../components/forms";
import { PageHeader } from "../../../components/shared";

type ReferralEditPageProps = {
  title: string;
};

export const ReferralEditPage = ({ title }: ReferralEditPageProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8">
        <ReferralEditForm />
      </div>
    </>
  );
};
