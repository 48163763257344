import TimeAgo from "timeago-react";
import useFetchDocument from "../../hooks/useFetchDocument";
import { ReferralProgramProfile } from "../../types";
import { ReferralProgramProfilePhoneNumber } from "../programs/ReferralProgramProfilePhoneNumber";

interface CycleReferralsListProps {
  referralsIds: string[];
}

const CycleReferralListItem = ({ referralId }: { referralId: string }) => {
  const snapshot = useFetchDocument<ReferralProgramProfile>({
    collection: "referralProgramProfiles",
    id: referralId,
  });

  if (snapshot.status === "loading") {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  if (snapshot.status === "error") {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  const referral = snapshot.data;

  return (
    <tr>
      <td>
        <div className="font-bold">
          {referral.name} {referral.lastName}
        </div>
        <div>{referral.email}</div>
        <div>
          <ReferralProgramProfilePhoneNumber
            referralProgramProfile={referral}
          />
        </div>
      </td>
      <td>
        <TimeAgo datetime={referral.createdAt} />
      </td>
    </tr>
  );
};

export const CycleReferralsList = ({
  referralsIds,
}: CycleReferralsListProps) => {
  return (
    <table className="table w-full">
      <tbody>
        {referralsIds.map((referralId) => (
          <CycleReferralListItem key={referralId} referralId={referralId} />
        ))}
      </tbody>
    </table>
  );
};
