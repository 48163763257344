import { BiTrash } from "react-icons/bi";

export const DismissedChatIndicator = () => {


  return (
    <span className="p-1 rounded-full bg-red-500 text-white">
      <BiTrash />
    </span>
  );
};