import {
  doc,
  getDoc,
} from "firebase/firestore";
import { PostPrivateDossier } from "../types";
import { db } from "./firebase";

const getById = async (postPrivateDossierId: string) => {
  const docRef = doc(db, "postPrivateDossiers", postPrivateDossierId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as PostPrivateDossier;
  } else {
    throw new Error("post-private-dossier-not-found");
  }
};

export const PostPrivateDossierService = {
  getById,
};
