import {
  CollectionReference,
  QueryConstraint,
  collection,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreCollection } from "reactfire";
import { Annotation } from "../types/annotation.type";

const COLLECTION = "manualProfileReviews";

type useFetchAnnotationsParams = {
  userId: string;
};

export default function useFetchAnnotations({
  userId,
}: useFetchAnnotationsParams) {
  const orderConstraints: QueryConstraint[] = [];

  orderConstraints.push(orderBy("createdAt", "asc"));

  const collectionRef = collection(
    db,
    COLLECTION,
    userId,
    "annotations"
  ) as CollectionReference<Annotation>;

  const q = query(collectionRef, ...orderConstraints);

  return useFirestoreCollection(q);
}
