import { useState } from "react";
import { PostService } from "../../../services/post.service";
import { Post, PostStatus } from "../../../types";

type PostStatusButtonsParams = {
  post: Post;
  onStatusUpdated: (status: PostStatus) => void;
};

type PostChanges = {
  status: PostStatus;
  isActive?: boolean;
};

export const PostStatusButtons = ({
  post,
  onStatusUpdated,
}: PostStatusButtonsParams) => {
  const [status, setStatus] = useState<PostStatus>(post.status);

  const handleStatusChange = async (status: PostStatus, isActive?: boolean) => {
    setStatus(status);
    const changes: PostChanges = { status };

    if (isActive !== undefined) {
      changes.isActive = isActive;
    }

    await PostService.update(post.id, changes);
    onStatusUpdated(status);
  };

  return (
    <>
      {["pending", "rejected", "blocked"].includes(status) && (
        <button
          className="btn btn-primary mr-2"
          onClick={async () => {
            await handleStatusChange("approved", true);
          }}
        >
          Approve
        </button>
      )}
      {["pending", "approved"].includes(status) && (
        <button
          className="btn btn-secondary mr-2"
          onClick={async () => {
            await handleStatusChange("rejected", false);
          }}
        >
          Reject
        </button>
      )}
      {["approved"].includes(status) && (
        <button
          className="btn"
          onClick={async () => {
            await handleStatusChange("blocked", false);
          }}
        >
          Block
        </button>
      )}
    </>
  );
};
