import { uuidv4 } from "@firebase/util";
import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { PromotionalBanner } from "../types/promotionalBanner.type";
import { db } from "./firebase";
import { DB_COLLECTIONS } from "../constants";

type GetAllSuscriptionParams = {
  onDataChange: (promotionalBanners: PromotionalBanner[]) => void;
};

const getAllSuscription = ({ onDataChange }: GetAllSuscriptionParams) => {
  const q = query(
    collection(
      db,
      DB_COLLECTIONS.PROMOTIONAL_BANNERS
    ) as CollectionReference<PromotionalBanner>,
    orderBy("name")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const categories: PromotionalBanner[] = [];

    snapshot.forEach((doc) => {
      categories.push(doc.data());
    });

    onDataChange(categories);
  });

  return unsubscribe;
};

const getById = async (categoryId: string) => {
  const docRef = doc(db, DB_COLLECTIONS.PROMOTIONAL_BANNERS, categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as PromotionalBanner;
  } else {
    throw new Error("category-not-found");
  }
};

type CreateParams = {
  coverUrlEn: string;
  coverUrlEs: string;
  isActive: boolean;
  name: string;
  url: string;
};

const create = (params: CreateParams) => {
  const id = uuidv4();
  const categoryRef = doc(db, DB_COLLECTIONS.PROMOTIONAL_BANNERS, id);

  return setDoc(categoryRef, {
    id,
    ...params,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
};

type UpdateParams = {
  coverUrlEn?: string;
  coverUrlEs?: string;
  isActive?: boolean;
  name?: string;
  url?: string;
};

const update = (promotionalBannerId: string, params: UpdateParams) => {
  const categoryRef = doc(
    db,
    DB_COLLECTIONS.PROMOTIONAL_BANNERS,
    promotionalBannerId
  );

  return updateDoc(categoryRef, {
    ...params,
    updatedAt: Date.now(),
  });
};

export const PromotionalBannerService = {
  getAllSuscription,
  getById,
  create,
  update,
};
