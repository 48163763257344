import { DocumentSnapshot } from "firebase/firestore";
import React, { useCallback, useMemo, useRef, useState } from "react";

import TimeAgo from "timeago-react";
import { ReferralProgramProfileTablePagination } from "./ReferralProgramProfileTablePagination";
import useFetchReferralProgramProfiles from "../../hooks/useFetchReferralProgramProfiles";
import SpanColumnName from "../../ui/SpanColumnName";
import { ReferralProgramProfilePhoneNumber } from "./ReferralProgramProfilePhoneNumber";

const ITEMS_PER_PAGE = 50;
interface ReferralProgramReferralsTableProps {
  referrerId?: string;
}

export const ReferralProgramReferralsTable = ({
  referrerId,
}: ReferralProgramReferralsTableProps) => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>("");

  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const groupedColumnCount = 6;

  const referralProgramProfilesFetch = useFetchReferralProgramProfiles({
    referredBy: referrerId,
    params: {
      cursor: cursors.current.get(page),
      itemsPerPage: ITEMS_PER_PAGE,
    },
  });

  const referrals = useMemo(() => {
    return (
      referralProgramProfilesFetch.data?.docs.map((doc) => doc.data()) ?? []
    );
  }, [referralProgramProfilesFetch.data]);

  const filterReferrals = useCallback(() => {
    return (
      referrals?.filter((profile) => {
        const fullName = `${profile.name} ${profile.lastName}`.toLowerCase();
        return fullName.includes(search.toLowerCase());
      }) ?? []
    );
  }, [referrals, search]);

  const filteredReferrals = filterReferrals();

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        cursors.current.set(
          page + 1,
          referralProgramProfilesFetch.data.docs[
            referralProgramProfilesFetch.data.docs.length - 1
          ]
        );

        return nextPage;
      });
    },
    [referralProgramProfilesFetch.data]
  );

  return (
    <>
      <div className="w-full mb-3">
        <input
          className="input w-full"
          placeholder="Search by name..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th className="hidden lg:table-cell">User</th>
              <th className="hidden lg:table-cell">Contact info</th>
              <th className={`hidden lg:table-cell text-center'}`}>
                Confirmed
              </th>
              <th className={`hidden lg:table-cell text-center'}`}>
                Valid for reward
              </th>
              <th className={`hidden lg:table-cell text-center'}`}>
                Granted reward
              </th>
              <th className="hidden lg:table-cell">Created At</th>
            </tr>
          </thead>
          <tbody>
            {referralProgramProfilesFetch.status === "loading" ? (
              <tr>
                <td colSpan={groupedColumnCount}>Loading...</td>
              </tr>
            ) : filteredReferrals.length > 0 ? (
              filteredReferrals.map((referral) => {
                let confirmedBadgeClass = null;
                let confirmedBadgeText = "no";

                let isGiftCardClass = null;
                let isGiftCardText = "no";

                let grantedRewardClass = null;
                let grantedRewardText = "no";

                if (referral.isConfirmed) {
                  confirmedBadgeClass = "badge-success";
                  confirmedBadgeText = "yes";
                }

                if (referral.isValidForReferral) {
                  isGiftCardClass = "badge-success";
                  isGiftCardText = "yes";
                }

                if (referral.referrerGiftCardId) {
                  grantedRewardClass = "badge-success";
                  grantedRewardText = "yes";
                }

                const confirmedBadge = (
                  <span className={`badge ${confirmedBadgeClass}`}>
                    {confirmedBadgeText}
                  </span>
                );

                const isValidForRewardBadge = referral.isConfirmed ? (
                  <span className={`badge ${isGiftCardClass}`}>
                    {isGiftCardText}
                  </span>
                ) : (
                  <></>
                );

                const grantedRewardBadge = referral.isConfirmed ? (
                  <span className={`badge ${grantedRewardClass}`}>
                    {grantedRewardText}
                  </span>
                ) : (
                  <></>
                );

                return (
                  <tr
                    key={referral.id}
                    className="flex flex-col lg:table-row mb-4 lg:mb-0"
                  >
                    <th>
                      <SpanColumnName>Name</SpanColumnName>
                      {`${referral.name} ${referral.lastName}`}
                    </th>
                    <td>
                      <div>
                        <SpanColumnName>Mail</SpanColumnName>
                        {referral.email}
                      </div>
                      {referral.phoneNumber && (
                        <div>
                          <SpanColumnName>Phone number</SpanColumnName>
                          <ReferralProgramProfilePhoneNumber
                            referralProgramProfile={referral}
                          />
                        </div>
                      )}
                    </td>
                    <td className="lg:text-center">
                      <SpanColumnName>Confirmed</SpanColumnName>
                      {confirmedBadge}
                    </td>
                    <td className="lg:text-center">
                      <SpanColumnName>Valid for reward</SpanColumnName>
                      {isValidForRewardBadge}
                    </td>
                    <td className="lg:text-center">
                      <SpanColumnName>Granted reward</SpanColumnName>
                      {grantedRewardBadge}
                    </td>
                    <td>
                      <SpanColumnName>Created at</SpanColumnName>
                      <TimeAgo datetime={referral.createdAt} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={groupedColumnCount} className="italic">
                  There are no associated profiles
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={groupedColumnCount}
                className="bg-transparent lg:bg-gray-100"
              >
                <ReferralProgramProfileTablePagination
                  referredBy={referrerId}
                  currentPage={page}
                  itemsPerPage={ITEMS_PER_PAGE}
                  onPageChange={onPageChanged}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
