import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/shared";

import { routeNames } from "../../../routes";
import ReferralsProgramCounters from "../ReferralProgramProfilesCounters";
import { ReferralProgramReferrersTable } from "../../../components/programs";

type ReferralIndexPageProps = {
  title: string;
};
export const ReferralIndexPage = ({ title }: ReferralIndexPageProps) => {
  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>{title}</PageHeader>
        <Link to={routeNames["programs.edit.referral"]}>
          <button className="btn btn-xs btn-neutral lg:btn-md">Edit</button>
        </Link>
      </div>

      <div className="mt-8">
        <ReferralsProgramCounters />
      </div>

      <div className="mt-8">
        <ReferralProgramReferrersTable />
      </div>
    </>
  );
};
