import { Route } from "react-router-dom";
import {
  CategoryIndexPage,
  CategoryNewPage,
  CategoryEditPage,
} from "../pages/categories";

import { CategoryPage } from "../pages/CategoryPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";

export const CategoryRoutes = (
  <>
    <Route
      path={routeNames["categories.index"]}
      element={
        <ProtectedRoute>
          <CategoryIndexPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["categories.new"]}
      element={
        <ProtectedRoute>
          <CategoryNewPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["categories.show"](":id")}
      element={
        <ProtectedRoute>
          <CategoryPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["categories.edit"](":id")}
      element={
        <ProtectedRoute>
          <CategoryEditPage />
        </ProtectedRoute>
      }
    />
  </>
);
