type PageHeaderProps = {
  className?: string;
  children: React.ReactNode;
};

export const PageHeader = ({ className, children }: PageHeaderProps) => {
  return (
    <div className={`text-3xl lg:text-4xl font-bold ${className}`}>
      {children}
    </div>
  );
};
