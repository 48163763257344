import PricePredictionDemo from "../../../components/ai/Demos/PricePredictionTest";


export const PricePrediction = () => {
  return (
    <div>
      <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Price Prediction</h3>
          <PricePredictionDemo />
        </div>
    </div>
  );
}