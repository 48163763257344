import { BiGift, BiUser, BiUserCheck } from "react-icons/bi";
import { ReferralProgramProfile } from "../../types";

interface ReferrerProfileCountersProps {
  referrer: ReferralProgramProfile;
}

export const ReferrerProfileCounters = ({
  referrer,
}: ReferrerProfileCountersProps) => {
  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full lg:w-auto">
      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUser size={24} />
        </div>
        <div className="stat-title">Referrals</div>
        <div className="stat-value">{referrer.referralsCount}</div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUserCheck size={24} />
        </div>
        <div className="stat-title">Confirmed referrals</div>
        <div className="stat-value">{referrer.confirmedReferralsCount}</div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiGift size={24} />
        </div>
        <div className="stat-title">Gift cards</div>
        <div className="stat-value">{referrer.giftCardCount}</div>
      </div>
    </div>
  );
};
