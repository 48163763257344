import {
  CollectionReference,
  QueryConstraint,
  collection,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { Category } from "../types";
import { useFirestoreCollection } from "reactfire";

const COLLECTION = "categories";

export default function useFetchCategories() {
  const order = orderBy("name", "asc");

  const constraints: QueryConstraint[] = [order];

  const collectionRef = collection(
    db,
    COLLECTION
  ) as CollectionReference<Category>;

  const q = query(collectionRef, ...constraints);

  return useFirestoreCollection(q);
}
