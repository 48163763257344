import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { StorageService } from "../../services/storage.service";
import { Category } from "../../types";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

type CategoryFormParams = {
  category?: Category;
  onSubmit: (formData: CategoryFormData) => Promise<void>;
};

type CategoryFormData = {
  active: boolean;
  coverUrl: string;
  isFeatured: boolean;
  name: string;
};

export const CategoryForm = ({ category, onSubmit }: CategoryFormParams) => {
  const validationSchema = Yup.object({
    active: Yup.boolean().required(),
    coverUrl: Yup.string().required("The cover image is required"),
    isFeatured: Yup.boolean().required(),
    name: Yup.string().required(),
  });

  const initialValues = {
    active: category?.active ?? true,
    coverUrl: category?.coverUrl ?? "",
    isFeatured: category?.isFeatured ?? false,
    name: category?.name ?? "",
  };

  const [file, setFile] = useState<File>();
  const [fileDataURL, setFileDataURL] = useState<string>();

  useEffect(() => {
    let fileReader: FileReader,
      isCancel = false;

    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target!;

        if (result && !isCancel) {
          setFileDataURL(result as string);
        }
      };
      fileReader.readAsDataURL(file);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleSubmit = async (values: any) => {
    try {
      if (file) {
        values["coverUrl"] = await StorageService.uploadFile({
          file,
          onProgress: (progress) => console.log(progress),
          remoteFolder: "categories",
        });
      }
      await onSubmit(values as CategoryFormData);
    } catch (err) {
      console.error(err);
    }
  };

  const renderError = (message: string) => (
    <p className="text-red-500">{message}</p>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
      }}
    >
      {(props) => (
        <Form>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Name</span>
            </label>
            <Field
              name="name"
              type="text"
              className="input input-bordered w-full max-w-xs"
              autoComplete="off"
            />
            <ErrorMessage name="name" render={renderError} />
          </div>

          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Cover image</span>
            </label>
            <img src={fileDataURL ?? category?.coverUrl} alt="" />
            <input
              accept="image/*"
              type="file"
              className="file-input file-input-bordered file-input-sm w-full max-w-xs mt-2"
              onChange={(e) => {
                setFile(e.target.files![0]);
                props.setFieldValue("coverUrl", e.target.files![0]);
              }}
            />
            <ErrorMessage name="coverUrl" render={renderError} />
          </div>

          <fieldset className="mt-8">
            <legend className="label">Options</legend>
            <div className="form-control w-full max-w-xs mt-4">
              <label className="label cursor-pointer">
                <span className="label-text">Active</span>
                <Field
                  name="active"
                  type="checkbox"
                  className="checkbox"
                />
              </label>
              <ErrorMessage name="active" render={renderError} />
            </div>

            <div className="form-control w-full max-w-xs mt-4">
              <label className="label cursor-pointer">
                <span className="label-text">Home page featured</span>
                <Field
                  name="isFeatured"
                  type="checkbox"
                  className="checkbox"
                />
              </label>
              <ErrorMessage name="isFeatured" render={renderError} />
            </div>
          </fieldset>

          <div className="mt-8">
            <button type="submit" className="btn btn-primary">
              Save
            </button>

            <Link to={routeNames["categories.index"]}>
              <button type="button" className="ml-2 btn">
                Cancel
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
