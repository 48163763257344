export const routeNames = {
  "categories.edit": (categoryId: string) => `/categories/${categoryId}/edit`,
  "categories.index": "/categories",
  "categories.new": "/categories/new",
  "categories.show": (categoryId: string) => `/categories/${categoryId}`,
  "gift-card-requests.index": "/gift-card-requests",
  "gift-card-requests.show": (giftCardRequestId: string) =>
    `/gift-card-requests/${giftCardRequestId}`,
  "knocks.index.active": "/active-requests",
  "knocks.index.all": "/all-requests",
  "knocks.index.closed": "/closed-requests",
  "knocks.index.expired": "/expired",
  "knocks.index.searching": "/searching-requests",
  "knocks.show": (knockId: string) => `/requests/${knockId}`,
  "manualProfileReviews.show": (userId: string) =>
    `/manual-profile-reviews/${userId}`,
  "phone-verifications.show": (phoneNumber: string) =>
    `/phone-verifications/${phoneNumber}`,
  "promotionalBanners.edit": (id: string) => `/promotional-banners/${id}/edit`,
  "promotionalBanners.index": "/promotional-banners",
  "promotionalBanners.new": "/promotional-banners/new",
  "posts.index.approved": "/approved-posts",
  "posts.index.blocked": "/blocked-posts",
  "posts.index.pending": "/pending-posts",
  "posts.index.rejected": "/rejected-posts",
  "posts.show": (postId: string) => `/posts/${postId}`,
  "programs.details.referral": (profileId: string) =>
    `/referral-program/${profileId}`,
  "programs.edit.referral": "/referral-program/edit",
  "programs.index.referral": "/referral-program",
  "subscriptions.index": "/subscriptions",
  "ai.assistant": "/ai/assistant",
  "ai.prices": "/ai/prices",
};
