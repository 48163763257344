import { Post } from "../../../types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { BiCheck } from "react-icons/bi";
import { useState } from "react";
import { callFirebaseFunction } from "../../../services/firebase";

type PostPhotoSliderParams = {
  editMode: boolean;
  post: Post;
  afterDelete?: (deletedImageUrls: Array<string>) => void;
};

export const PostPhotoSlider = ({
  editMode,
  post,
  afterDelete,
}: PostPhotoSliderParams) => {
  const [selectedImages, setSelectedImages] = useState<Array<string>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onImageSelected = (url: string) => {
    if (selectedImages.includes(url)) {
      setSelectedImages(selectedImages.filter((id) => id !== url));
    } else {
      setSelectedImages([...selectedImages, url]);
    }
  };

  const isImageSelected = (url: string) => selectedImages.includes(url);

  const onImagesDelete = async () => {
    try {
      setIsDeleting(true);
      await callFirebaseFunction("deletePostImages", {
        postId: post.id,
        imageUrls: selectedImages,
      });

      setSelectedImages([]);

      if (afterDelete) {
        afterDelete(selectedImages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Swiper
        slidesPerView="auto"
        spaceBetween={30}
        className="mt-4 h-96"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {post.imageUrls.map((url, index) => {
          return (
            <SwiperSlide
              key={`post-image-${index}`}
              style={{ width: "max-content" }}
            >
              <div className="relative h-full">
                <img
                  src={url}
                  alt=""
                  className={`h-full ${
                    isImageSelected(url) && "border border-salmon-500"
                  }`}
                />

                {editMode && (
                  <div className="absolute top-2 right-2">
                    <div
                      className="rounded-full border border-salmon-500 bg-white cursor-pointer h-6 w-6 flex justify-center items-center"
                      onClick={() => onImageSelected(url)}
                    >
                      {isImageSelected(url) && (
                        <BiCheck className="w-full h-full" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {editMode && selectedImages.length > 0 && (
        <div className="mt-4">
          <button
            className="btn btn-primary btn-sm"
            disabled={isDeleting}
            onClick={() => onImagesDelete()}
          >
            {isDeleting ? (
              <>Deleting {selectedImages.length} images...</>
            ) : (
              <>({selectedImages.length}) Delete selected images</>
            )}
          </button>
        </div>
      )}
    </>
  );
};
