import { PostsList } from "../../../components/lists/PostsList";
import { PageHeader } from "../../../components/shared";
import { PostStatus } from "../../../types";

type PostIndexPageProps = {
  status: PostStatus;
  title: string;
};

export const PostIndexPage = ({ status, title }: PostIndexPageProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8">
        <PostsList status={status} />
      </div>
    </>
  );
};
