import Moment from "moment";
import React from 'react'

interface DateTimeDisplayProps {
  date: Date;
  type?: 'date' | 'datetime';
}

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({ date, type = 'datetime'}) => {
  const formatType = type === 'datetime' ? "MMM Do yyyy HH:mm:ss" : "MMM Do YY";
  const formattedDate = Moment(date).format(formatType);
  return (
    <span>{formattedDate}</span>
  );
}

export default DateTimeDisplay
