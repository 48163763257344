import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

type AnnotationCreateParams = {
  reviewed: boolean;
};

const update = (userId: string, params: AnnotationCreateParams) => {
  const docRef = doc(db, "manualProfileReviews", userId);

  return updateDoc(docRef, {
    ...params,
    reviewedAt: params.reviewed ? Date.now() : null,
    reviewedBy: auth.currentUser?.uid,
  });
};

export const ManualProfileReviewService = {
  update,
};
