import { Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { ReferralProgramService } from "../../services";
import { ReferralProgram } from "../../types";
import InputField from "../../ui/InputField";
import {
  referralProgramInit,
  referralProgramReader,
  updateObjectWithLanguage,
} from "../../utils/referralProgramFormatter";
import { toast } from "react-toastify";
import referralEditFormValidationSchema from "./ReferralEditFormValidationSchema";

export const ReferralEditForm = () => {
  const LABEL_CLASSLIST = "font-semibold my-1";
  const TEXTAREA_CLASSLIST = "w-full mt-2 textarea textarea-bordered";

  const [initialValues] = useState(referralProgramInit);
  const [reloadReferralProgram, setReloadReferralProgram] =
    useState<boolean>(true);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedLanguage(selectedValue);
  };

  const showNotification = (value: boolean) => {
    if (value)
      toast.success("Program updated successfully!", {
        position: "top-right",
      });
    else toast.error("Error updating the program!", { position: "top-right" });
  };

  const [referralProgram, setReferralProgram] = useState<ReferralProgram>();

  useEffect(() => {
    if (reloadReferralProgram) {
      ReferralProgramService.getReferralProgram().then((response) => {
        setReferralProgram(response);
      });
      setReloadReferralProgram(false);
    }
  }, [reloadReferralProgram]);

  const handleSubmit = async (values: any) => {
    await ReferralProgramService.update(values)
      .then(() => {
        showNotification(true);
        setReloadReferralProgram(true);
      })
      .catch(() => showNotification(false));
  };

  const renderError = (message: string) => (
    <p className="text-red-500">{message}</p>
  );

  const fieldWithLabel = (
    label: string,
    name: string,
    className: string = ""
  ) => (
    <div className={className}>
      <label className={LABEL_CLASSLIST} htmlFor={name}>
        {label}
      </label>
      <InputField
        as="textarea"
        id={name}
        name={name}
        className={TEXTAREA_CLASSLIST}
      />
      <ErrorMessage name={name} render={renderError} />
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={referralEditFormValidationSchema}
      onSubmit={(values) => {
        handleSubmit(
          updateObjectWithLanguage(referralProgram, values, selectedLanguage)
        ).then((r) => {});
      }}
    >
      {(initialValue) => (
        <Form>
          <HandleInitialValue
            initialValue={initialValue}
            getReferralProgram={referralProgram}
            language={selectedLanguage}
          />
          <div>
            <div className="flex flex-col w-full lg:flex-row">
              <div className="font-bold" style={{ marginBlock: "auto" }}>
                Control the program's status with this switch
              </div>
              <div className="divider lg:divider-horizontal" />
              <div className="flex items-center space-x-4 p-2">
                <div>Off</div>
                <div>
                  <input
                    type="checkbox"
                    checked={initialValue.values.active}
                    onChange={(e) => {
                      initialValue.setFieldValue("active", e.target.checked);
                    }}
                    className="toggle bg-black"
                  />
                </div>
                <div>On</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full lg:flex-row mt-5 mb-8">
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className=" font-semibold">
                  Select the program's language
                </span>
              </div>
              <select
                className="select select-bordered"
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
              </select>
            </label>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {fieldWithLabel("Main claim", "mainClaim")}
            {fieldWithLabel("Secondary main claim", "secondaryClaim")}
            {fieldWithLabel("Alternative main claim", "alternativeMainClaim")}
            {fieldWithLabel(
              "Alternative secondary claim",
              "alternativeSecondaryClaim"
            )}
            {fieldWithLabel(
              "Share action button text",
              "shareActionButtonText"
            )}
            {fieldWithLabel("Share content", "shareContent")}
            {fieldWithLabel("Rules", "rules")}
            {fieldWithLabel("Gift card instructions", "giftCardInstructions")}
          </div>

          <div className="mt-8 divider font-bold">Onboarding</div>
          <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
            {fieldWithLabel(
              "Share floating button text",
              "shareFloatingButtonText",
              "lg:col-offset-1"
            )}
            {fieldWithLabel(
              "Onboarding title",
              "onboardingTitle",
              "lg:col-start-1"
            )}
            {fieldWithLabel("Onboarding top text", "onboardingTopText")}
            {fieldWithLabel("Onboarding first step", "onboardingFirstStep")}
            {fieldWithLabel("Onboarding second step", "onboardingSecondStep")}
            {fieldWithLabel("Onboarding third step", "onboardingThirdStep")}
            {fieldWithLabel("Onboarding bottom text", "onboardingBottomText")}
            {fieldWithLabel("Onboarding button text", "onboardingButtonText")}
          </div>

          <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="grid grid-cols-1 gap-8">
              <div className="divider font-bold">
                Account creation notification
              </div>
              {fieldWithLabel("Title", "accountCreationNotification.title")}
              {fieldWithLabel("Content", "accountCreationNotification.content")}
            </div>
            <div className="grid grid-cols-1 gap-8">
              <div className="divider font-bold">
                Account verification notification
              </div>
              {fieldWithLabel("Title", "accountVerificationNotification.title")}
              {fieldWithLabel(
                "Content",
                "accountVerificationNotification.content"
              )}
            </div>
            <div className="grid grid-cols-1 gap-8">
              <div className="divider font-bold">
                Referral reward notification
              </div>
              {fieldWithLabel("Title", "referralRewardNotification.title")}
              {fieldWithLabel("Content", "referralRewardNotification.content")}
            </div>
          </div>
          <div className="py-8">
            <button type="submit" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
const HandleInitialValue = ({
  initialValue,
  language,
  getReferralProgram = {},
}: any) => {
  useEffect(() => {
    const updateValues = () => {
      if (getReferralProgram !== null) {
        const readReferralProgram = referralProgramReader(
          getReferralProgram,
          language
        );
        Object.keys(initialValue.values).forEach((key) => {
          if (readReferralProgram.hasOwnProperty(key)) {
            initialValue.setFieldValue(key, readReferralProgram[key]);
          }
        });
      }
    };
    updateValues();
  }, [getReferralProgram, language]);
  return null;
};
