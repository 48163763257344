import {
  DocumentSnapshot,
  QueryConstraint,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  CollectionReference,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreCollection } from "reactfire";
import { ReferralProgramProfile } from "../types";

const COLLECTION = "referralProgramProfiles";

type useFetchReferralProgramProfiles = {
  referredBy?: string;
  params: {
    cursor?: DocumentSnapshot;
    itemsPerPage: number;
  };
};

export default function useFetchReferenceProfile({
  referredBy,
  params,
}: useFetchReferralProgramProfiles) {
  const orderConstraints: QueryConstraint[] = [];
  const whereConstraints: QueryConstraint[] = [];

  const referred = referredBy !== undefined ? referredBy : "";

  if (referredBy !== undefined) {
    whereConstraints.push(where("referredBy", "==", referred));
    orderConstraints.push(orderBy("createdAt", "desc"));
  } else {
    whereConstraints.push(where("referralsCount", ">", 0));
    orderConstraints.push(orderBy("referralsCount", "desc"));
  }

  const constraints: QueryConstraint[] = [
    ...whereConstraints,
    ...orderConstraints,
    limit(params.itemsPerPage),
  ];

  if (params.cursor) {
    constraints.push(startAfter(params.cursor));
  }

  const collectionRef = collection(
    db,
    COLLECTION
  ) as CollectionReference<ReferralProgramProfile>;

  const q = query(collectionRef, ...constraints);

  return useFirestoreCollection(q);
}
