import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { SubscriptionsIndexPage } from "../pages/subscriptions/SubscriptionsIndexPage";

export const SubscriptionRoutes = (
  <>
    <Route
      path={routeNames["subscriptions.index"]}
      element={
        <ProtectedRoute>
          <SubscriptionsIndexPage />
        </ProtectedRoute>
      }
    />
  </>
);
