import { useMemo } from "react";
import useFetchCategories from "../../hooks/useFetchCategories";
import useSubscriptionsCount from "../../hooks/useFetchSubscriptions";
import SubscriptionListItem from "./SubscriptionListItem";

const SubscriptionList = () => {
  const { subscriptions, loading } = useSubscriptionsCount();

  const categoriesFetch = useFetchCategories();

  const categories = useMemo(() => {
    return categoriesFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [categoriesFetch.data]);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      {subscriptions.map((subscription) => (
        <SubscriptionListItem
          key={subscription.id}
          subscription={subscription}
          categories={categories}
        />
      ))}
    </div>
  );
};

export default SubscriptionList;
