import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryForm } from "../../../components/forms";
import { PageHeader } from "../../../components/shared";
import { routeNames } from "../../../routes";
import { CategoriesService } from "../../../services/categories.service";
import { Category } from "../../../types";

export const CategoryEditPage = () => {
  const categoryId = useParams()["id"];
  const [category, setCategory] = useState<Category>();

  const navigate = useNavigate();

  useEffect(() => {
    const getCategory = async () => {
      if (categoryId) {
        setCategory(await CategoriesService.getById(categoryId));
      }
    };

    getCategory();
  }, [categoryId]);

  if (category === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageHeader>Edit categorys</PageHeader>
      <div className="mt-8">
        <CategoryForm
          category={category!}
          onSubmit={async (formData) => {
            await CategoriesService.updateCategory(categoryId!, {
              active: formData.active,
              coverUrl: formData.coverUrl,
              isFeatured: formData.isFeatured,
              name: formData.name,
            });
            navigate(routeNames["categories.index"]);
          }}
        />
      </div>
    </>
  );
};
