import { BiComment, BiCommentAdd } from "react-icons/bi";
import useToggle from "../../hooks/useToggle";
import useFetchAnnotations from "../../hooks/useFetchAnnotations";
import { Modal } from "../shared/Modal/Modal";
import { useMemo } from "react";
import ManualProfileReviewAnnotationForm from "./ManualProfileReviewAnnotationForm";
import moment from "moment";

const ManualProfileReviewAnnotations = ({ userId }: { userId: string }) => {
  const [showModal, setShowModal] = useToggle(false);
  const annotationsFetch = useFetchAnnotations({
    userId,
  });

  const annotations = useMemo(() => {
    return annotationsFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [annotationsFetch.data]);

  const handleClick = () => {
    if (!showModal) setShowModal.set(true);
  };

  return (
    <div className="badge badge-dark cursor-pointer" onClick={handleClick}>
      <Modal
        show={showModal}
        onCloseModal={() => setShowModal.set(false)}
        title="Annotations"
      >
        <div className="p-4 text-base-content">
          {annotations ? (
            annotations.map((annotation, index) => (
              <div key={index} className="p-2 border mb-4">
                <div className="">{annotation.content}</div>
                <div className="text-xs">
                  {moment(annotation.createdAt).format("MMM Do YY - h:mm a")}
                </div>
              </div>
            ))
          ) : (
            <div>Loading...</div>
          )}
          <ManualProfileReviewAnnotationForm userId={userId} />
        </div>
      </Modal>
      <div className="flex items-center gap-1">
        {annotations.length > 0 ? (
          <>
            <BiComment />
            View notes ({annotations.length})
          </>
        ) : (
          <>
            <BiCommentAdd />
            Add note
          </>
        )}
      </div>
    </div>
  );
};
export default ManualProfileReviewAnnotations;
