import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { CategoriesService } from "../../services/categories.service";
import { Category } from "../../types";

export const CategoriesList = () => {
  const [categories, setCategories] = useState<Category[]>();

  useEffect(() => {
    const unsubscribe = CategoriesService.getAllSuscription({
      onDataChange: setCategories,
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th className="hidden lg:table-cell">Slug</th>
              <th>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {categories === undefined ? (
              <tr>
                <td colSpan={3}>Loading categories...</td>
              </tr>
            ) : (
              categories!.map((category: Category) => {
                return (
                  <tr key={`category-${category.id}`}>
                    <td>
                      <div className="flex items-start lg:items-center flex-col lg:flex-row gap-2">
                        <span>{category.name}</span>
                        {category.active && (
                          <span className="badge badge-success">Active</span>
                        )}
                        {category.isFeatured && (
                          <span className="badge badge-info">Featured</span>
                        )}
                      </div>
                    </td>
                    <td className="hidden lg:table-cell">{category.slug}</td>
                    <td>
                      <Link to={routeNames["categories.edit"](category.id)}>
                        <button className="btn btn-secondary btn-sm">
                          Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
