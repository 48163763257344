import { doc, getDoc } from "firebase/firestore";
import { Profile } from "../types";
import { db } from "./firebase";

const COLLECTION = "profiles";

const getById = async (profileId: string) => {
  const docRef = doc(db, COLLECTION, profileId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as Profile;
  } else {
    throw new Error("not-found" + profileId);
  }
};

export const ProfileService = {
  getById,
};
