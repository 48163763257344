import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/shared";
import useFetchPhoneVerification from "../../hooks/useFetchPhoneVerification";
import { PhoneVerification } from "../../types";

export const PhoneVerificationShowPage = () => {
  const { phoneNumber } = useParams<{ phoneNumber: string }>();
  const snapshot = useFetchPhoneVerification(phoneNumber!);

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error: {snapshot.error?.message}</div>;
  }

  const phoneVerification = snapshot.data as PhoneVerification;

  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>Phone verification</PageHeader>
      </div>
      <div className="mt-8">
        <ul className="flex flex-col gap-1">
          {Object.entries(phoneVerification).map(([key, value]) => (
            <li key={key}>
              <strong>{key}: </strong>
              {`${value}`}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
