import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { GiftCardRequestIndexPage } from "../pages/gift-card-requests/GiftCardRequestIndexPage";
import { GiftCardRequestShowPage } from "../pages/gift-card-requests/GiftCardRequestShowPage";

export const GiftCardRequestRoutes = (
  <>
    <Route
      path={routeNames["gift-card-requests.index"]}
      element={
        <ProtectedRoute>
          <GiftCardRequestIndexPage
            status="pending"
            title="Pending Gift Card Requests"
          />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["gift-card-requests.show"](":giftCardRequestId")}
      element={
        <ProtectedRoute>
          <GiftCardRequestShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
