import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, logInWithEmailAndPassword } from "../../services/firebase";
import { ErrorTranslations } from "../../utils/errorTranslations";
import { FullPageLoader } from "../../components/shared";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setIsAuthLoading(true);
      return;
    }

    setIsAuthLoading(false);

    if (user && isProcessing === false) navigate("/categories");
  }, [user, loading, navigate, isProcessing]);

  return isAuthLoading ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="relative bg-orange-500">
        <div className="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
          <div className="flex-col flex  self-center p-10 sm:max-w-5xl xl:max-w-2xl">
            <div className="self-start lg:flex flex-col  text-white lg:w-80">
              <h1 className="mb-3 font-bold text-5xl">Dingdoor</h1>
              <p className="pr-3">Admin panel</p>
            </div>
          </div>
          <div className="flex justify-center self-center">
            <div className="p-12 bg-white mx-auto rounded-2xl w-100 ">
              <div className="mb-4">
                <h3 className="font-semibold text-2xl text-gray-800">
                  Sign In{" "}
                </h3>
                <p className="text-gray-500">Please sign in to your account.</p>
              </div>
              <form
                className="space-y-5"
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    setIsProcessing(true);
                    await logInWithEmailAndPassword(email, password);
                  } catch (err: any) {
                    console.log(err);
                    setErrorMessage(
                      ErrorTranslations[err.code ?? err] ??
                        ErrorTranslations["auth/generic"]
                    );
                  } finally {
                    setIsProcessing(false);
                  }
                }}
              >
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 tracking-wide">
                    Email
                  </label>
                  <input
                    className=" w-full text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-orange-400"
                    type="mail"
                    placeholder="mail@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                  />
                </div>
                <div className="space-y-2">
                  <label className="mb-5 text-sm font-medium text-gray-700 tracking-wide">
                    Password
                  </label>
                  <input
                    className="w-full content-center text-base px-4 py-2 border  border-gray-300 rounded-lg focus:outline-none focus:border-orange-400"
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {errorMessage && (
                  <div className="bg-red-200 rounded p-2">{errorMessage}</div>
                )}
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center bg-orange-300  hover:bg-orange-500 text-gray-100 p-3  rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <div className="pt-5 text-center text-gray-400 text-xs">
                <span>Copyright © {new Date().getFullYear()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
