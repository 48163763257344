import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PageHeader } from "../../components/shared";
import useFetchDocument from "../../hooks/useFetchDocument";
import { GiftCardRequest, ReferralProgramProfile } from "../../types";
import { CycleReferralsList } from "../../components/lists/CycleReferralsList";
import { ReferralProgramProfilePhoneNumber } from "../../components/programs/ReferralProgramProfilePhoneNumber";
import { ReferrerProfileCounters } from "../programs/ReferrerProfileCounters";
import { routeNames } from "../../routes";
import { callFirebaseFunction } from "../../services/firebase";
import { FirebaseError } from "firebase/app";

const GiftCardRequestReferrer = ({ referrerId }: { referrerId: string }) => {
  const snapshot = useFetchDocument<ReferralProgramProfile>({
    collection: "referralProgramProfiles",
    id: referrerId,
  });

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error fetching data</div>;
  }

  const referrer = snapshot.data;

  return (
    <>
      <PageHeader>
        <div>Gift Card Request</div>
        <div className="text-xl">
          {`${referrer.name} ${referrer.lastName}`}
          {referrer.isBanned && (
            <span className="text-red-500 ml-2 text-sm">(Banned)</span>
          )}
        </div>
        <div className="text-sm text-gray-500">{referrer.email}</div>
        <div className="text-sm text-gray-500">
          <ReferralProgramProfilePhoneNumber
            referralProgramProfile={referrer}
            isPill={true}
          />
        </div>
      </PageHeader>

      <div className="mt-8">
        <ReferrerProfileCounters referrer={referrer} />
      </div>
    </>
  );
};

export const GiftCardRequestShowPage = () => {
  const { giftCardRequestId } = useParams();

  const giftCardRequestSnapshot = useFetchDocument<GiftCardRequest>({
    id: giftCardRequestId!,
    collection: "giftCardRequests",
  });

  if (giftCardRequestSnapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (giftCardRequestSnapshot.status === "error") {
    return <div>Error fetching data</div>;
  }

  const giftCardRequest = giftCardRequestSnapshot.data;

  const resolveGiftCardRequest = async (action: "approve" | "reject") => {
    try {
      await callFirebaseFunction("resolveGiftCardRequest", {
        giftCardRequestId: giftCardRequest?.id,
        action,
      });
      toast.success("Gift card request resolved successfully", {
        position: "top-right",
      });
    } catch (error) {
      if (error instanceof FirebaseError) console.error(error.message);
      toast.error("Error resolving the gift card request", {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <div className="flex items-start justify-between">
        <div>
          <GiftCardRequestReferrer referrerId={giftCardRequest?.userId!} />
        </div>

        <Link to={routeNames["gift-card-requests.index"]}>
          <button className="btn btn-xs lg:btn-md btn-info">
            Back to index
          </button>
        </Link>
      </div>

      <div className="grid lg:grid-cols-2 gap-y-4 gap-x-8">
        <div>
          <h2 className="mt-8 text-xl font-bold">Referrals</h2>
          <div className="mt-4">
            <CycleReferralsList
              referralsIds={giftCardRequest?.currentCycleReferralsIds}
            />
          </div>
        </div>

        <div>
          {giftCardRequest.status === "pending" ? (
            <>
              <h2 className="mt-8 text-xl font-bold">Actions</h2>
              <div className="mt-4 flex flex-col lg:flex-row gap-4">
                <button
                  className="btn btn-success"
                  onClick={() => resolveGiftCardRequest("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => resolveGiftCardRequest("reject")}
                >
                  Reject
                </button>
              </div>
            </>
          ) : (
            <div className="mt-8">
              <h2 className="text-xl font-bold">Status</h2>
              <div className="mt-4">
                <span className="text-lg font-bold">
                  {giftCardRequest.status}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
