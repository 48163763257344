import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { StorageService } from "../../services/storage.service";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PromotionalBanner } from "../../types/promotionalBanner.type";

type PromotionalBannerFormParams = {
  promotionalBanner?: PromotionalBanner;
  onSubmit: (formData: PromotionalBannerFormData) => Promise<void>;
};

type PromotionalBannerFormData = {
  coverUrlEs: string;
  coverUrlEn: string;
  isActive: boolean;
  name: string;
  url: string;
};

export const PromotionalBannerForm = ({
  promotionalBanner,
  onSubmit,
}: PromotionalBannerFormParams) => {
  const validationSchema = Yup.object({
    coverUrlEn: Yup.string().required("The english cover image is required"),
    coverUrlEs: Yup.string().required("The spanish cover image is required"),
    isActive: Yup.boolean().required(),
    name: Yup.string().required(),
    url: Yup.string(),
  });

  const initialValues = {
    coverUrlEn: promotionalBanner?.coverUrlEn ?? "",
    coverUrlEs: promotionalBanner?.coverUrlEs ?? "",
    isActive: promotionalBanner?.isActive ?? true,
    name: promotionalBanner?.name ?? "",
    url: promotionalBanner?.url ?? "",
  };

  const [fileEs, setFileEs] = useState<File>();
  const [fileEsDataURL, setFileEsDataURL] = useState<string>();
  const [fileEn, setFileEn] = useState<File>();
  const [fileEnDataURL, setFileEnDataURL] = useState<string>();

  console.log(promotionalBanner);

  useEffect(() => {
    let fileReader: FileReader,
      isCancel = false;

    if (fileEs) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target!;

        if (result && !isCancel) {
          setFileEsDataURL(result as string);
        }
      };
      fileReader.readAsDataURL(fileEs);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileEs]);

  useEffect(() => {
    let fileReader: FileReader,
      isCancel = false;

    if (fileEn) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target!;

        if (result && !isCancel) {
          setFileEnDataURL(result as string);
        }
      };
      fileReader.readAsDataURL(fileEn);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileEn]);

  const handleSubmit = async (values: any) => {
    try {
      if (fileEs) {
        values["coverUrlEs"] = await StorageService.uploadFile({
          file: fileEs,
          onProgress: (progress) => console.log(progress),
          remoteFolder: "promotionalBanners",
        });
      }

      if (fileEn) {
        values["coverUrlEn"] = await StorageService.uploadFile({
          file: fileEn,
          onProgress: (progress) => console.log(progress),
          remoteFolder: "promotionalBanners",
        });
      }
      await onSubmit(values as PromotionalBannerFormData);
    } catch (err) {
      console.error(err);
    }
  };

  const renderError = (message: string) => (
    <p className="text-red-500">{message}</p>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
      }}
    >
      {(props) => (
        <Form>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Name</span>
            </label>
            <Field
              name="name"
              type="text"
              className="input input-bordered w-full max-w-xs"
              autoComplete="off"
            />
            <ErrorMessage name="name" render={renderError} />
          </div>

          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">English Cover image</span>
            </label>
            <img src={fileEnDataURL ?? promotionalBanner?.coverUrlEn} alt="" />
            <input
              accept="image/*"
              type="file"
              className="file-input file-input-bordered file-input-sm w-full max-w-xs mt-2"
              onChange={(e) => {
                setFileEn(e.target.files![0]);
                props.setFieldValue("coverUrlEn", e.target.files![0]);
              }}
            />
            <ErrorMessage name="coverUrlEn" render={renderError} />
          </div>

          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Spanish Cover image</span>
            </label>
            <img src={fileEsDataURL ?? promotionalBanner?.coverUrlEs} alt="" />
            <input
              accept="image/*"
              type="file"
              className="file-input file-input-bordered file-input-sm w-full max-w-xs mt-2"
              onChange={(e) => {
                setFileEs(e.target.files![0]);
                props.setFieldValue("coverUrlEs", e.target.files![0]);
              }}
            />
            <ErrorMessage name="coverUrlEs" render={renderError} />
          </div>

          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">URL</span>
            </label>
            <Field
              name="url"
              type="text"
              className="input input-bordered w-full max-w-xs"
              autoComplete="off"
            />
            <ErrorMessage name="url" render={renderError} />
          </div>

          <fieldset className="mt-8">
            <legend className="label">Options</legend>
            <div className="form-control w-full max-w-xs mt-4">
              <label className="label cursor-pointer">
                <span className="label-text">Active</span>
                <Field name="isActive" type="checkbox" className="checkbox" />
              </label>
              <ErrorMessage name="isActive" render={renderError} />
            </div>
          </fieldset>

          <div className="mt-8">
            <button type="submit" className="btn btn-primary">
              Save
            </button>

            <Link to={routeNames["promotionalBanners.index"]}>
              <button type="button" className="ml-2 btn">
                Cancel
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
