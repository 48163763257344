import { RotateLoader } from "react-spinners";

type FullPageLoaderProps = {
  className?: string;
  loaderColor?: string;
};

export const FullPageLoader = ({
  className,
  loaderColor,
}: FullPageLoaderProps) => {
  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        className ?? "bg-orange-500"
      }`}
    >
      <div>
        <RotateLoader color={`${loaderColor ?? "#fff"}`} />
      </div>
    </div>
  );
};
