import { uuidv4 } from "@firebase/util";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";

const _getFileExtension = (file: File) => {
  const fileName = file.name;
  return fileName.substring(fileName.lastIndexOf(".") + 1);
};

const _remoteFilePath = (file: File, folder: string) => {
  const ext = _getFileExtension(file);
  return `uploads/${folder}/${uuidv4()}.${ext}`;
};

const uploadFile = ({
  file,
  onProgress,
  remoteFolder,
}: {
  file: File;
  onProgress: (percent: number) => void;
  remoteFolder: string;
}) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, _remoteFilePath(file, remoteFolder)); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        onProgress(percent);
      },
      (err) => reject(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url);
        });
      }
    );
  });
};

const removeFile = (url: string) => {
  const storageRef = ref(storage, url);
  console.log(storageRef.fullPath);
};

export const StorageService = { uploadFile, removeFile };
