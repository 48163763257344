import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import { type Subscription } from "../types/subscription.type";

const useFetchSubscriptions = () => {
  const [loading, setLoading] = useState(true);
  const [monthlyAppleCount, setMonthlyAppleCount] = useState(0);
  const [monthlyApplePercentage, setMonthlyApplePercentage] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [monthlyGoogleCount, setMonthlyGoogleCount] = useState(0);
  const [monthlyGooglePercentage, setMonthlyGooglePercentage] = useState(0);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [yearlyAppleCount, setYearlyAppleCount] = useState(0);
  const [yearlyApplePercentage, setYearlyApplePercentage] = useState(0);
  const [yearlyCount, setYearlyCount] = useState(0);
  const [yearlyGoogleCount, setYearlyGoogleCount] = useState(0);
  const [yearlyGooglePercentage, setYearlyGooglePercentage] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      setLoading(true);

      const subscriptionsRef = collection(db, "subscriptions");

      const allDocsSnapshot = await getDocs(subscriptionsRef);

      const fetchedSubscriptions: Subscription[] = [];

      let fetchedMonthlyAppleCount = 0;
      let fetchedMonthlyCount = 0;
      let fetchedMonthlyGoogleCount = 0;
      let fetchedYearlyAppleCount = 0;
      let fetchedYearlyCount = 0;
      let fetchedYearlyGoogleCount = 0;

      for (const doc of allDocsSnapshot.docs) {
        const subscription = doc.data() as Subscription;

        if (
          subscription.status !== "active" ||
          subscription.expiresAt < Date.now()
        )
          continue;

        if (!subscription.productId) {
          console.error("Subscription has no productId", subscription);
          continue;
        }

        if (subscription.productId.includes("monthly")) {
          fetchedMonthlyCount++;

          if (subscription.provider === "apple") {
            fetchedMonthlyAppleCount++;
          }

          if (subscription.provider === "google") {
            fetchedMonthlyGoogleCount++;
          }
        } else if (subscription.productId.includes("yearly")) {
          fetchedYearlyCount++;

          if (subscription.provider === "apple") {
            fetchedYearlyAppleCount++;
          }

          if (subscription.provider === "google") {
            fetchedYearlyGoogleCount++;
          }
        } else {
          console.error("Unknown subscription type", subscription.productId);
        }

        fetchedSubscriptions.push(subscription);
      }

      const monthlyApplePercentage =
        fetchedMonthlyCount === 0
          ? 0
          : Math.round(
              (fetchedMonthlyAppleCount / fetchedMonthlyCount) * 100 * 100
            ) / 100;

      const monthlyGooglePercentage =
        fetchedMonthlyCount === 0
          ? 0
          : Math.round((100 - monthlyApplePercentage) * 100) / 100;

      setMonthlyCount(fetchedMonthlyCount);
      setMonthlyAppleCount(fetchedMonthlyAppleCount);
      setMonthlyApplePercentage(monthlyApplePercentage);
      setMonthlyGoogleCount(fetchedMonthlyGoogleCount);
      setMonthlyGooglePercentage(monthlyGooglePercentage);

      const yearlyApplePercentage =
        fetchedYearlyCount === 0
          ? 0
          : Math.round(
              (fetchedYearlyAppleCount / fetchedYearlyCount) * 100 * 100
            ) / 100;

      const yearlyGooglePercentage =
        fetchedYearlyCount === 0
          ? 0
          : Math.round((100 - yearlyApplePercentage) * 100) / 100;

      setYearlyAppleCount(fetchedYearlyAppleCount);
      setYearlyApplePercentage(yearlyApplePercentage);
      setYearlyGoogleCount(fetchedYearlyGoogleCount);
      setYearlyGooglePercentage(yearlyGooglePercentage);
      setYearlyCount(fetchedYearlyCount);

      setSubscriptions(
        fetchedSubscriptions.sort((a, b) => b.createdAt - a.createdAt)
      );

      setLoading(false);
    };

    fetchCounts();
  }, []);

  return {
    loading,
    monthlyAppleCount,
    monthlyApplePercentage,
    monthlyCount,
    monthlyGoogleCount,
    monthlyGooglePercentage,
    subscriptions,
    yearlyAppleCount,
    yearlyApplePercentage,
    yearlyCount,
    yearlyGoogleCount,
    yearlyGooglePercentage,
  };
};

export default useFetchSubscriptions;
