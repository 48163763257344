import { doc, DocumentReference } from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreDocData } from "reactfire";
import { ReferralProgramProfile } from "../types";

const COLLECTION = "referralProgramProfiles";

export default function useReferralProgramProfile(id: string) {
  const docRef = doc(
    db,
    COLLECTION,
    id
  ) as DocumentReference<ReferralProgramProfile>;
  return useFirestoreDocData<ReferralProgramProfile>(docRef);
}
