import useFetchPhoneVerification from "../../hooks/useFetchPhoneVerification";
import { PhoneVerification } from "../../types";

interface PhoneVerificationDetailsProps {
  phoneNumber: string;
}

export const PhoneVerificationDetails = ({
  phoneNumber,
}: PhoneVerificationDetailsProps) => {
  const snapshot = useFetchPhoneVerification(phoneNumber);

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error: {snapshot.error?.message}</div>;
  }

  const phoneVerification = snapshot.data as PhoneVerification;

  const labelClass = "font-bold text-sm";

  return (
    <ul className="flex flex-col gap-1">
      <li>
        <strong className={labelClass}>Phone Number: </strong>
        {phoneNumber}
      </li>

      <li>
        <strong className={labelClass}>Status: </strong>
        {phoneVerification.hlrValid ? (
          <span className="bg-success text-white rounded py-0.5 px-2">
            Valid
          </span>
        ) : (
          <span className="bg-error text-white rounded py-0.5 px-2">
            Invalid
          </span>
        )}
      </li>

      <li>
        <strong className={labelClass}>Number Type: </strong>
        {phoneVerification.numberType}
      </li>

      <li>
        <strong className={labelClass}>Current Network: </strong>
        {phoneVerification.currentNetwork}
      </li>

      <li>
        <strong className={labelClass}>Location: </strong>
        {phoneVerification.location}
      </li>

      <li>
        <strong className={labelClass}>Country: </strong>
        {phoneVerification.country}
      </li>
    </ul>
  );
};
