import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  DocumentReference,
} from "firebase/firestore";
import { ServiceRequest } from "../types";
import { db } from "./firebase";

const COLLECTION = "serviceRequests";

type GetAllByKnockIdSuscriptionParams = {
  onDataChange: (serviceRequest: ServiceRequest[]) => void;
  knockId: string;
};

const getAllByKnockIdSuscription = ({
  knockId,
  onDataChange,
}: GetAllByKnockIdSuscriptionParams) => {
  const q = query(
    collection(db, COLLECTION) as CollectionReference<ServiceRequest>,
    where("knockId", "==", knockId)
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const serviceRequests: ServiceRequest[] = [];

    snapshot.forEach((doc) => {
      serviceRequests.push(_serviceRequestFromData(doc.data()));
    });

    onDataChange(serviceRequests);
  });

  return unsubscribe;
};

const getById = async (id: string) => {
  const docRef = doc(
    db,
    "serviceRequests",
    id
  ) as DocumentReference<ServiceRequest>;
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return _serviceRequestFromData(docSnap.data());
  } else {
    throw new Error("post-not-found");
  }
};

const _serviceRequestFromData = (data: ServiceRequest) => {
  return { ...data, createdAt: new Date(data.createdAt) } as ServiceRequest;
};

export const ServiceRequestService = {
  getAllByKnockIdSuscription,
  getById,
};
