import { useState } from "react";
import { AnnotationService } from "../../services/annotation.service";

export const ManualProfileReviewAnnotationForm = ({
  userId,
}: {
  userId: string;
}) => {
  const [newContent, setNewContent] = useState<string>("");

  const handleSubmit = async () => {
    if (newContent.trim() !== "") {
      await AnnotationService.add(userId, {
        content: newContent,
        createdAt: Date.now(),
      });

      setNewContent("");
    } else {
      alert("Please write something before submitting the annotation.");
    }
  };

  return (
    <div>
      <textarea
        className="textarea textarea-bordered w-full text-base-content"
        value={newContent}
        onChange={(e) => setNewContent(e.target.value)}
      />
      <div className="mt-2">
        <button className="btn btn-primary" onClick={() => handleSubmit()}>
          Add annotation
        </button>
      </div>
    </div>
  );
};

export default ManualProfileReviewAnnotationForm;
