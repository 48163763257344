import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  query,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { Category } from "../types";

const COLLECTION_NAME = "categories";

export const useCategoryQuery = (categoryId: string, subscribe?: boolean) => {
  const ref = doc(
    db,
    COLLECTION_NAME,
    categoryId
  ) as DocumentReference<Category>;

  return useFirestoreDocumentData<Category>(
    [COLLECTION_NAME, categoryId],
    ref,
    {
      subscribe: subscribe,
    },
    {
      refetchOnMount: "always",
    }
  );
};

export const useCategoriesQuery = (subscribe?: boolean) => {
  const ref = query(
    collection(db, COLLECTION_NAME)
  ) as CollectionReference<Category>;

  return useFirestoreQueryData<Category>([COLLECTION_NAME], ref, {
    subscribe: subscribe,
  });
};
