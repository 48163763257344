import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../components/shared";
import { routeNames } from "../../routes";
import { PromotionalBanner } from "../../types/promotionalBanner.type";
import { PromotionalBannerService } from "../../services/promotionalBanner.service";
import { PromotionalBannerForm } from "../../components/forms/PromotionalBannerForm";

export const PromotionalBannerEditPage = () => {
  const promotionalBannerId = useParams()["id"];
  const [promotionalBanner, setPromotionalBanner] =
    useState<PromotionalBanner>();

  const navigate = useNavigate();

  useEffect(() => {
    const getPromotionalBanner = async () => {
      if (promotionalBannerId) {
        setPromotionalBanner(
          await PromotionalBannerService.getById(promotionalBannerId)
        );
      }
    };

    getPromotionalBanner();
  }, [promotionalBannerId]);

  if (promotionalBanner === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageHeader>Edit banner</PageHeader>
      <div className="mt-8">
        <PromotionalBannerForm
          promotionalBanner={promotionalBanner!}
          onSubmit={async (formData) => {
            await PromotionalBannerService.update(promotionalBannerId!, {
              coverUrlEn: formData.coverUrlEn,
              coverUrlEs: formData.coverUrlEs,
              isActive: formData.isActive,
              name: formData.name,
              url: formData.url,
            });
            navigate(routeNames["promotionalBanners.index"]);
          }}
        />
      </div>
    </>
  );
};
