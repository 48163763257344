import { BiX } from "react-icons/bi";

type ModalProps = {
  show: boolean;
  title: string;
  children: React.ReactNode;
  onCloseModal: () => void;
};

export const Modal = ({ children, title, show, onCloseModal }: ModalProps) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-20"
      onClick={() => onCloseModal()}
    >
      <div
        className="relative top-20 mx-auto py-3 px-5 border w-full lg:w-[600px] shadow-lg rounded-md bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-lg font-bold mb-4 flex items-center justify-between text-base-content">
          <span>{title}</span>
          <BiX className="cursor-pointer" onClick={() => onCloseModal()} />
        </div>
        {children}
      </div>
    </div>
  );
};
