import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  DocumentReference,
  orderBy,
} from "firebase/firestore";
import { Knock, KnockStatus } from "../types";
import { db } from "./firebase";

const COLLECTION = "knocks";

type GetAllSuscriptionParams = {
  onDataChange: (knocks: Knock[]) => void;
  status: KnockStatus;
};

const getAllSuscription = ({
  status,
  onDataChange,
}: GetAllSuscriptionParams) => {
  const q = query(
    collection(db, COLLECTION) as CollectionReference<Knock>,
    where("status", "==", status),
    orderBy("createdAt", "desc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const knocks: Knock[] = [];

    snapshot.forEach((doc) => {
      const knockData = doc.data();
      knocks.push({ ...knockData, createdAt: new Date(knockData.createdAt), updatedAt: new Date(knockData.updatedAt) });
    });

    onDataChange(knocks);
  });

  return unsubscribe;
};

const getById = async (categoryId: string) => {
  const docRef = doc(db, COLLECTION, categoryId) as DocumentReference<Knock>;
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return _knockFromData(docSnap.data());
  } else {
    throw new Error("not-found");
  }
};

const _knockFromData = (data: Knock) => {
  return { ...data, createdAt: new Date(data.createdAt), updatedAt: new Date(data.updatedAt)} as Knock;
};

// Function to get N random knocks
const getRandomKnocks = async (N: number, categoryId: string) => {
  const q = collection(db, "knocks") as CollectionReference<Knock>; // Replace "knocks" with your actual collection name

  // Get all knocks
  const snapshot = await getDocs(q);

  const allKnocks: Knock[] = [];

  snapshot.forEach((doc) => {
    const knockData = doc.data();
    if (knockData.categoryId === categoryId) {
      allKnocks.push({ ...knockData, createdAt: new Date(knockData.createdAt) });
    }
  });

  // Shuffle the array and return N random knocks
  const shuffledKnocks = allKnocks.sort(() => 0.5 - Math.random());
  const randomKnocks = shuffledKnocks.slice(0, N);

  return randomKnocks;
};

export const KnockService = {
  getAllSuscription,
  getById,
  getRandomKnocks, // Added this new function to the KnockService
};

