import { doc, DocumentReference } from "firebase/firestore";
import { db } from "../services/firebase";
import { useFirestoreDocData } from "reactfire";
import { PhoneVerification } from "../types";

const COLLECTION = "phoneVerifications";

export default function useFetchPhoneVerification(id: string) {
  const docRef = doc(
    db,
    COLLECTION,
    id
  ) as DocumentReference<PhoneVerification>;
  return useFirestoreDocData<PhoneVerification>(docRef);
}
