import { BiUser } from "react-icons/bi";
import useSubscriptionsCount from "../../hooks/useFetchSubscriptions";
import { FaApple, FaGoogle } from "react-icons/fa";

export const SubscriptionCounters = () => {
  const {
    loading,
    monthlyAppleCount,
    monthlyApplePercentage,
    monthlyCount,
    monthlyGoogleCount,
    monthlyGooglePercentage,
    yearlyAppleCount,
    yearlyApplePercentage,
    yearlyCount,
    yearlyGoogleCount,
    yearlyGooglePercentage,
  } = useSubscriptionsCount();

  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full lg:w-auto">
      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUser size={24} />
        </div>
        <div className="stat-title">Monthly subscriptions</div>
        <div className="stat-value">{loading ? "..." : monthlyCount}</div>
        <div className="stat-desc flex">
          <div className="flex items-center">
            <FaApple size={16} className="mr-1" />
            {loading
              ? "..."
              : `${monthlyAppleCount} (${monthlyApplePercentage}%)`}
          </div>
          <div className="w-2"></div>
          <div className="flex items-center">
            <FaGoogle size={16} className="mr-1" />
            {loading
              ? "..."
              : `${monthlyGoogleCount} (${monthlyGooglePercentage}%)`}
          </div>
        </div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUser size={24} />
        </div>
        <div className="stat-title">Yearly subscriptions</div>
        <div className="stat-value">{loading ? "..." : yearlyCount}</div>
        <div className="stat-desc flex items-center">
          <div className="flex items-center">
            <FaApple size={16} className="mr-1" />
            {loading
              ? "..."
              : `${yearlyAppleCount} (${yearlyApplePercentage}%)`}
          </div>
          <div className="w-2"></div>
          <div className="flex items-center">
            <FaGoogle size={16} className="mr-1" />
            {loading
              ? "..."
              : `${yearlyGoogleCount} (${yearlyGooglePercentage}%)`}
          </div>
        </div>
      </div>
    </div>
  );
};
