import {
  CollectionReference,
  QueryConstraint,
  collection,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { GiftCardRequest } from "../types";
import { useFirestoreCollection } from "reactfire";

const COLLECTION = "giftCardRequests";

export default function useFetchGiftCardRequests(status?: string) {
  const order = orderBy("createdAt", "desc");
  const constraints: QueryConstraint[] = [order];

  if (status) {
    constraints.push(where("status", "==", status));
  }

  const collectionRef = collection(
    db,
    COLLECTION
  ) as CollectionReference<GiftCardRequest>;

  const q = query(collectionRef, ...constraints);

  return useFirestoreCollection<GiftCardRequest>(q);
}
