import React, { useState, useEffect } from "react";
import { CategoriesService, getPricePrediction } from "../../../services";
import { PricePredictionResponse } from "../../../types";
const PricePredictionDemo: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>("");
    const [prediction, setPrediction] = useState<PricePredictionResponse | null>(null);
    const [activeCategories, setActiveCategories] = useState<{ id: string; name: string }[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [inputError, setInputError] = useState<string | null>(null);
    const [locality, setLocality] = useState<string>("");
    const [county, setCounty] = useState<string>("");

    const handlePredictPrice = async () => {
        if (!inputText || !selectedCategory) {
            setInputError("Please fill in the knock description.");
            return;
        }

        setInputError(null);
        setLoading(true);

        const payload = {
            description: inputText,
            categoryId: selectedCategory,
            locality: locality,
            county: county,
        };


        try {
            const response : PricePredictionResponse = await getPricePrediction(payload);
            setPrediction(response);
        } catch (error) {
            console.error("Error predicting price:", error);
            setInputError(String(error));
        }

        setLoading(false);
    };

    useEffect(() => {
        const fetchActiveCategories = async () => {
            const categories = await CategoriesService.getActiveCategories();
            setActiveCategories(categories);

            if (categories.length > 0) {
                const randomCategory = categories[Math.floor(Math.random() * categories.length)];
                setSelectedCategory(randomCategory.id);
            }
        };

        fetchActiveCategories();

        return () => {
            setActiveCategories([]);
            setSelectedCategory("");
        };
    }, []);

    return (
        <div className="flex flex-col gap-1">
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="p-2 border rounded-lg mr-4 w-1/4"
            >
                {activeCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </select>

            <textarea
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Write a knock for price prediction"
                className={`w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 ${inputError ? 'border-red-500' : ''}`}
            />
            {inputError && <p className="text-red-500 mt-1">{inputError}</p>}

            <div className="flex items-center gap-1 mb-2">
                <label className="text-gray-700 w-40">Locality:</label>
                <input
                    type="text"
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}
                    placeholder="Enter Locality"
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:border-blue-500"
                />
            </div>

            <div className="flex items-center gap-1 mb-2">
                <label className="text-gray-700 w-40">County:</label>
                <input
                    type="text"
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                    placeholder="Enter County"
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:border-blue-500"
                />
            </div>

            <button
                type="submit"
                onClick={handlePredictPrice}
                className={`w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition ${
                    loading ? 'cursor-not-allowed' : ''
                }`}
                disabled={loading}
            >
                {loading ? "Predicting Price..." : "Predict"}
            </button>

            {prediction && (
                <div className="mt-4">
                    <label className="text-lg font-bold text-gray-700">Predicted Price:</label>
                    <p className="text-xl font-semibold text-blue-600">${prediction?.price}</p>
                </div>
            )}
        </div>
    );
};

export default PricePredictionDemo;
