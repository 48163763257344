import { DEFAULT_REFERRALS_FOR_REWARD } from "../constants";
import { ReferralProgram, ReferralProgramForm } from "../types";

export const referralProgramInit = {
  accountCreationNotification: {
    content: "",
    title: "",
  },
  accountVerificationNotification: {
    content: "",
    title: "",
  },
  active: true,
  alternativeMainClaim: "",
  alternativeSecondaryClaim: "",
  giftCardInstructions: "",
  mainClaim: "",
  onboardingBottomText: "",
  onboardingButtonText: "",
  onboardingFirstStep: "",
  onboardingSecondStep: "",
  onboardingThirdStep: "",
  onboardingTitle: "",
  onboardingTopText: "",
  referralRewardNotification: {
    content: "",
    title: "",
  },
  referralsForReward: DEFAULT_REFERRALS_FOR_REWARD,
  rules: "",
  secondaryClaim: "",
  shareActionButtonText: "",
  shareFloatingButtonText: "",
  shareContent: "",
};

export const referralProgramReader = (
  refProgram: ReferralProgram,
  language: string
): ReferralProgramForm => {
  if (Object.keys(refProgram).length <= 0) {
    return {} as ReferralProgramForm;
  }

  const {
    accountCreationNotification,
    accountVerificationNotification,
    active,
    alternativeMainClaim,
    alternativeSecondaryClaim,
    giftCardInstructions,
    mainClaim,
    nextRewardProgressText,
    onboardingBottomText,
    onboardingButtonText,
    onboardingFirstStep,
    onboardingSecondStep,
    onboardingThirdStep,
    onboardingTitle,
    onboardingTopText,
    referralRewardNotification,
    referralsForReward,
    rules,
    secondaryClaim,
    shareActionButtonText,
    shareContent,
    shareFloatingButtonText,
  } = refProgram;
  return {
    accountCreationNotification: {
      title: accountCreationNotification[language]?.title,
      content: accountCreationNotification[language]?.content,
    },
    accountVerificationNotification: {
      title: accountVerificationNotification[language]?.title,
      content: accountVerificationNotification[language]?.content,
    },
    active: active,
    alternativeMainClaim: alternativeMainClaim[language],
    alternativeSecondaryClaim: alternativeSecondaryClaim[language],
    giftCardInstructions: giftCardInstructions[language],
    mainClaim: mainClaim[language],
    nextRewardProgressText: nextRewardProgressText[language],
    onboardingBottomText: onboardingBottomText[language],
    onboardingButtonText: onboardingButtonText[language],
    onboardingFirstStep: onboardingFirstStep[language],
    onboardingSecondStep: onboardingSecondStep[language],
    onboardingThirdStep: onboardingThirdStep[language],
    onboardingTitle: onboardingTitle[language],
    onboardingTopText: onboardingTopText[language],
    referralRewardNotification: {
      title: referralRewardNotification[language]?.title,
      content: referralRewardNotification[language]?.content,
    },
    referralsForReward: referralsForReward,
    rules: rules[language],
    secondaryClaim: secondaryClaim[language],
    shareActionButtonText: shareActionButtonText[language],
    shareContent: shareContent[language],
    shareFloatingButtonText: shareFloatingButtonText[language],
  };
};

type LanguageObject = {
  [key: string]: string | LanguageObject;
};

export const updateObjectWithLanguage = (
  originalObject: any,
  newObject: any,
  language: string
): LanguageObject => {
  let updatedObject = originalObject;
  for (const key in originalObject) {
    if (newObject.hasOwnProperty(key)) {
      if (
        typeof originalObject[key] === "object" &&
        originalObject[key] !== null
      ) {
        const languageValue = (newObject[key] &&
          (newObject[key] as LanguageObject)[language]) as string;
        (updatedObject[key] as LanguageObject)[language] =
          languageValue !== undefined ? languageValue : newObject[key];
      } else if (newObject[key] !== undefined) {
        updatedObject[key] = newObject[key];
      }
    }
  }
  return updatedObject;
};
