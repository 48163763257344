import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { PromotionalBanner } from "../../types/promotionalBanner.type";
import { PromotionalBannerService } from "../../services/promotionalBanner.service";

export const PromotionalBannersList = () => {
  const [promotionalBanners, setPromotionalBanners] =
    useState<PromotionalBanner[]>();

  useEffect(() => {
    const unsubscribe = PromotionalBannerService.getAllSuscription({
      onDataChange: setPromotionalBanners,
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th className="hidden lg:table-cell">Slug</th>
              <th>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {promotionalBanners === undefined ? (
              <tr>
                <td colSpan={3}>Loading promotional banners...</td>
              </tr>
            ) : (
              promotionalBanners!.map(
                (promotionalBanner: PromotionalBanner) => {
                  return (
                    <tr key={`promotional-banner-${promotionalBanner.id}`}>
                      <td>
                        <div className="flex items-start lg:items-center flex-col lg:flex-row gap-2">
                          <span>{promotionalBanner.name}</span>
                          {promotionalBanner.isActive && (
                            <span className="badge badge-success">Active</span>
                          )}
                        </div>
                      </td>
                      <td className="hidden lg:table-cell">
                        {promotionalBanner.url}
                      </td>
                      <td>
                        <Link
                          to={routeNames["promotionalBanners.edit"](
                            promotionalBanner.id
                          )}
                        >
                          <button className="btn btn-secondary btn-sm">
                            Edit
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                }
              )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
