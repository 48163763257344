import { Route } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import ManualProfileReviewShowPage from "../pages/manualProfileReviews/ManualProfileReviewShowPage";

export const ManualProfileReviewRoutes = (
  <>
    <Route
      path={routeNames["manualProfileReviews.show"](":userId")}
      element={
        <ProtectedRoute>
          <ManualProfileReviewShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
