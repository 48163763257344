import { Link } from "react-router-dom";
import { CategoriesList } from "../../../components/lists/CategoriesList";
import { PageHeader } from "../../../components/shared";
import { routeNames } from "../../../routes";

export const CategoryIndexPage = () => {
  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>Categories</PageHeader>

        <Link to={routeNames["categories.new"]}>
          <button className="btn btn-xs lg:btn-md">Create category</button>
        </Link>
      </div>
      <div className="mt-8">
        <CategoriesList />
      </div>
    </>
  );
};
