import {
  CollectionReference,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { Chat } from "../types";

type getByIdParams = {
  knockId: string;
  providerId: string;
};

const get = async ({ knockId, providerId }: getByIdParams) => {
  const q = query(
    collection(db, "chats") as CollectionReference<Chat>,
    where("knockId", "==", knockId),
    where("providerId", "==", providerId),
    limit(1)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    throw new Error("chat-not-found");
  }

  const docSnap = querySnapshot.docs[0];

  return docSnap.data() as Chat;
};

export const ChatService = {
  get,
};
