import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { GiftCardRequest, GiftCardRequestStatus } from "../../types";

import useFetchGiftCardRequests from "../../hooks/useFetchGiftCardRequests";
import TimeAgo from "timeago-react";

type GiftCardRequestsListProps = {
  status?: GiftCardRequestStatus;
};

export const GiftCardRequestsList = ({ status }: GiftCardRequestsListProps) => {
  const snapshot = useFetchGiftCardRequests(status);

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error fetching data</div>;
  }

  const giftCardRequests = snapshot.data.docs.map((doc) => {
    return doc.data() as GiftCardRequest;
  });

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th className="hidden lg:table-cell">Created at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {giftCardRequests.map((giftCardRequest) => {
              return (
                <tr key={giftCardRequest.id}>
                  <td>
                    <div>
                      <span className="font-bold">
                        {giftCardRequest.name} {giftCardRequest.lastName}{" "}
                      </span>
                      {giftCardRequest.isReferrerBanned && (
                        <span className="text-red-500 ml-1 text-xs">
                          (Banned)
                        </span>
                      )}
                    </div>
                    <div className="text-sm">{giftCardRequest.email}</div>
                    <div className="text-sm">{giftCardRequest.phoneNumber}</div>
                  </td>
                  <td className="hidden lg:table-cell">
                    <TimeAgo datetime={giftCardRequest.createdAt} />
                  </td>
                  <td>
                    <Link
                      to={`${routeNames["gift-card-requests.show"](
                        giftCardRequest.id
                      )}`}
                      className="btn"
                    >
                      Review
                    </Link>
                  </td>
                </tr>
              );
            })}

            {giftCardRequests.length === 0 && (
              <tr>
                <td colSpan={3}>No pending gift card requests found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
