import * as Yup from "yup";

const requiredString = (message: string) => Yup.string().required(message);

const referralEditFormValidationSchema = Yup.object().shape({
  accountCreationNotification: Yup.object({
    content: requiredString("Account creation content is required"),
    title: requiredString("Account creation title is required"),
  }).required("Account creation notification is required"),
  accountVerificationNotification: Yup.object({
    content: requiredString("Account verification notification is required"),
    title: requiredString(
      "Account verification notification title is required"
    ),
  }).required("Request creation notification is required"),
  alternativeMainClaim: requiredString("Alternative main claim is required"),
  alternativeSecondaryClaim: requiredString(
    "Alternative secondary claim is required"
  ),
  giftCardInstructions: requiredString("Gift card instructions are required"),
  mainClaim: requiredString("Main claim is required"),

  onboardingTitle: requiredString("Onboarding title is required"),
  onboardingTopText: requiredString("Onboarding top text is required"),
  onboardingFirstStep: requiredString("Onboarding first step is required"),
  onboardingSecondStep: requiredString("Onboarding second step is required"),
  onboardingThirdStep: requiredString("Onboarding third step is required"),
  onboardingBottomText: requiredString("Onboarding bottom text is required"),
  onboardingButtonText: requiredString("Onboarding button text is required"),

  referralRewardNotification: Yup.object({
    content: requiredString("Referral reward notification content is required"),
    title: requiredString("Referral reward notification title is required"),
  }).required("Referral reward notification is required"),
  rules: requiredString("Rules are required"),
  secondaryClaim: requiredString("Secondary claim is required"),
  shareActionButtonText: requiredString("Share action button text is required"),
  shareContent: Yup.string().required("Share content is required"),
  shareFloatingButtonText: Yup.string().required(
    "Share floating button text is required"
  ),
});

export default referralEditFormValidationSchema;
