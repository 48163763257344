import { useEffect, useState } from "react";
import {
  CollectionReference,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { type Post } from "../types";

const useFetchProfilePosts = (profileId?: string) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      const postsRef = collection(db, "posts") as CollectionReference<Post>;
      const q = query(postsRef, where("userId", "==", profileId));
      const postsSnap = await getDocs(q);

      setPosts(postsSnap.docs.map((doc) => doc.data() as Post));
      setLoading(false);
    };
    if (profileId) fetchPosts();
  }, [profileId]);

  return {
    loading,
    posts,
  };
};

export default useFetchProfilePosts;
