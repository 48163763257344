import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "./firebase";
import {
  ReferralProgram,
  NotificationDetails,
  LocalizedString,
  LocalizedNotificationDetails,
} from "../types";
import { DEFAULT_REFERRALS_FOR_REWARD } from "../constants";

const COLLECTION = "programs";
const REFERRAL_PROGRAM_ID = "referral_program";

const getReferralProgram = async () => {
  const docRef = doc(
    db,
    COLLECTION,
    REFERRAL_PROGRAM_ID
  ) as DocumentReference<ReferralProgram>;
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return _programFromData(docSnap.data());
  } else {
    const newDocument = createNewReferralProgram();
    await setDoc(docRef, newDocument);
    return _programFromData(newDocument);
  }
};

const _programFromData = (data: ReferralProgram) => {
  return {
    ...data,
    createdAt: new Date(data.createdAt).getTime(),
  } as ReferralProgram;
};

type PostUpdateParams = {
  program: ReferralProgram;
};

const update = async (params: PostUpdateParams) => {
  const programRef = doc(db, COLLECTION, REFERRAL_PROGRAM_ID);
  const collectionRef = collection(programRef, "programDetails");

  const activeProgram = params.program?.active
    ? "Active program"
    : "Inactive program";
  const newHistory = {
    profileId: auth.currentUser?.uid,
    action: "(".concat(activeProgram.concat(") Update values")),
    createdAt: new Date().getTime(),
  };
  try {
    await addDoc(collectionRef, newHistory);
  } catch (error) {
    console.error("Error al agregar el elemento:", error);
  }

  return updateDoc(programRef, params);
};

const createNewReferralProgram = (): ReferralProgram => {
  return {
    id: REFERRAL_PROGRAM_ID,
    accountCreationNotification: emptyLocalizedNotificationDetails(),
    accountVerificationNotification: emptyLocalizedNotificationDetails(),
    active: false,
    alternativeMainClaim: emptyLocalizedString(),
    alternativeSecondaryClaim: emptyLocalizedString(),
    createdAt: new Date().getTime(),
    giftCardInstructions: emptyLocalizedString(),
    mainClaim: emptyLocalizedString(),
    nextRewardProgressText: emptyLocalizedString(),
    onboardingBottomText: emptyLocalizedString(),
    onboardingButtonText: emptyLocalizedString(),
    onboardingFirstStep: emptyLocalizedString(),
    onboardingSecondStep: emptyLocalizedString(),
    onboardingThirdStep: emptyLocalizedString(),
    onboardingTitle: emptyLocalizedString(),
    onboardingTopText: emptyLocalizedString(),
    referralRewardNotification: emptyLocalizedNotificationDetails(),
    referralsForReward: DEFAULT_REFERRALS_FOR_REWARD,
    rules: emptyLocalizedString(),
    shareActionButtonText: emptyLocalizedString(),
    shareFloatingButtonText: emptyLocalizedString(),
    secondaryClaim: emptyLocalizedString(),
    shareContent: emptyLocalizedString(),
  };
};

const emptyLocalizedNotificationDetails = (): LocalizedNotificationDetails => {
  return {
    en: emptyNotificationDetails(),
    es: emptyNotificationDetails(),
  };
};

const emptyLocalizedString = (): LocalizedString => {
  return {
    en: "",
    es: "",
  };
};

const emptyNotificationDetails = (): NotificationDetails => {
  return {
    title: "",
    content: "",
  };
};

export const ReferralProgramService = {
  getReferralProgram,
  update,
};
