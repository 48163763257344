import { DocumentSnapshot } from "firebase/firestore";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { ReferralProgramProfile } from "../../types";
import TimeAgo from "timeago-react";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes";
import { ReferralProgramProfileTablePagination } from "./ReferralProgramProfileTablePagination";
import useFetchReferralProgramProfiles from "../../hooks/useFetchReferralProgramProfiles";
import SpanColumnName from "../../ui/SpanColumnName";
import { ReferralProgramProfilePhoneNumber } from "./ReferralProgramProfilePhoneNumber";

const ITEMS_PER_PAGE = 20;

const RecordRow = ({ referrer }: { referrer: ReferralProgramProfile }) => {
  const navigate = useNavigate();

  return (
    <tr key={referrer.id} className="flex flex-col lg:table-row mb-4 lg:mb-0">
      <th>
        <SpanColumnName>Name</SpanColumnName>
        {`${referrer.name} ${referrer.lastName}`}
        {referrer.isBanned ? (
          <span className="text-red-500 ml-2 text-xs">(Banned)</span>
        ) : (
          ""
        )}
      </th>
      <td>
        <div>
          <SpanColumnName>Mail</SpanColumnName>
          {referrer.email}
        </div>
        {referrer.phoneNumber && (
          <div>
            <SpanColumnName>Phone number</SpanColumnName>
            <ReferralProgramProfilePhoneNumber
              referralProgramProfile={referrer}
            />
          </div>
        )}
      </td>
      <td className="lg:text-center">
        <SpanColumnName>Created accounts</SpanColumnName>
        {referrer.referralsCount}
      </td>
      <td className="lg:text-center">
        <SpanColumnName>Confirmed accounts</SpanColumnName>
        {referrer.confirmedReferralsCount}
      </td>
      <td className="lg:text-center">
        <SpanColumnName>Gift Cards</SpanColumnName>
        {referrer.giftCardCount}
      </td>
      <td className="lg:table-cell">
        <SpanColumnName>Updated at</SpanColumnName>
        <TimeAgo datetime={referrer.updatedAt} />
      </td>
      <td>
        <button
          className="btn btn-primary btn-sm mr-2 btn-block lg:btn-inline-block"
          onClick={() => {
            navigate(routeNames["programs.details.referral"](referrer.id), {
              state: referrer,
            });
          }}
        >
          See More...
        </button>
      </td>
    </tr>
  );
};

export const ReferralProgramReferrersTable = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const groupedColumnCount = 7;

  const referralProgramProfilesFetch = useFetchReferralProgramProfiles({
    params: {
      cursor: cursors.current.get(page),
      itemsPerPage: ITEMS_PER_PAGE,
    },
  });

  const referrers = useMemo(() => {
    return (
      referralProgramProfilesFetch.data?.docs.map((doc) => doc.data()) ?? []
    );
  }, [referralProgramProfilesFetch.data]);

  const filterReferrers = useCallback(() => {
    return (
      referrers?.filter((referrer) => {
        const fullName = `${referrer.name} ${referrer.lastName}`.toLowerCase();
        return fullName.includes(search.toLowerCase());
      }) ?? []
    );
  }, [referrers, search]);

  const filteredReferrers = filterReferrers();

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        cursors.current.set(
          page + 1,
          referralProgramProfilesFetch.data.docs[
            referralProgramProfilesFetch.data.docs.length - 1
          ]
        );

        return nextPage;
      });
    },
    [referralProgramProfilesFetch.data]
  );

  return (
    <>
      <div className="w-full mb-3">
        <input
          className="input w-full"
          placeholder="Search by name..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th className="hidden lg:table-cell">User</th>
              <th className="hidden lg:table-cell">Contact info</th>
              <th className="hidden lg:table-cell text-center">
                Created accounts
              </th>
              <th className="hidden lg:table-cell text-center">
                Confirmed accounts
              </th>
              <th className="hidden lg:table-cell text-center">Gift Cards</th>
              <th className="hidden lg:table-cell">Updated At</th>
              <th className="hidden lg:table-cell">Action</th>
            </tr>
          </thead>
          <tbody>
            {referralProgramProfilesFetch.status === "loading" ? (
              <tr>
                <td colSpan={groupedColumnCount}>Loading...</td>
              </tr>
            ) : filteredReferrers.length > 0 ? (
              filteredReferrers.map((referrer) => {
                return <RecordRow key={referrer.id} referrer={referrer} />;
              })
            ) : (
              <tr>
                <td colSpan={groupedColumnCount} className="italic">
                  There are no associated profiles
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={groupedColumnCount}
                className="bg-transparent lg:bg-gray-100"
              >
                <ReferralProgramProfileTablePagination
                  currentPage={page}
                  itemsPerPage={ITEMS_PER_PAGE}
                  onPageChange={onPageChanged}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
