import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "./firebase";

type AnnotationCreateParams = {
  content: string;
  createdAt: number;
};

const add = (userId: string, params: AnnotationCreateParams) => {
  const collectionRef = collection(
    db,
    "manualProfileReviews",
    userId,
    "annotations"
  );

  return addDoc(collectionRef, { ...params, userId: auth.currentUser?.uid });
};

export const AnnotationService = {
  add,
};
