import { BiGift, BiMobile, BiUser, BiUserCheck } from "react-icons/bi";
import useReferralProfilesCount from "../../hooks/useReferralProfilesCount";

const ReferralProgramProfilesCounters = () => {
  const {
    totalCount,
    confirmedCount,
    confirmedMobile,
    giftCardCount,
    loading,
  } = useReferralProfilesCount();

  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full lg:w-auto">
      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUser size={24} />
        </div>
        <div className="stat-title">Created accounts</div>
        <div className="stat-value">
          {loading ? "..." : totalCount.toLocaleString()}
        </div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiUserCheck size={24} />
        </div>
        <div className="stat-title">Confirmed accounts</div>
        <div className="stat-value">
          {loading ? "..." : confirmedCount.toLocaleString()}
        </div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiMobile size={24} />
        </div>
        <div className="stat-title">Confirmed mobile accounts</div>
        <div className="stat-value">
          {loading ? "..." : confirmedMobile.toLocaleString()}
        </div>
      </div>

      <div className="stat">
        <div className="stat-figure text-dark">
          <BiGift size={24} />
        </div>
        <div className="stat-title">Gift cards</div>
        <div className="stat-value">
          {loading ? "..." : giftCardCount.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default ReferralProgramProfilesCounters;
