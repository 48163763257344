import { ReferralProgramProfile } from "../../types";

export const PhoneNumberValidationTag = ({
  referralProgramProfile,
  isPill,
}: {
  isPill?: boolean;
  referralProgramProfile: ReferralProgramProfile;
}) => {
  const validClassName = isPill
    ? "rounded text-xs bg-success text-white px-2 py-0.5"
    : "text-xs text-green-500";
  const invalidClassName = isPill
    ? "rounded text-xs bg-error text-white px-2 py-0.5"
    : "text-xs text-red-500";

  if (referralProgramProfile.isPhoneNumberValid === undefined) {
    return <span className="text-xs text-gray-500">Unknown</span>;
  }

  return referralProgramProfile.isPhoneNumberValid ? (
    <span className={validClassName}>Valid</span>
  ) : (
    <span className={invalidClassName}>Invalid</span>
  );
};
