import useFetchDocument from "../../hooks/useFetchDocument";
import { AnnotationService } from "../../services/annotation.service";
import { ManualProfileReviewService } from "../../services/manualProfileReview.service";
import { ManualProfileReview } from "../../types/manualProfileReview.type";
import ManualProfileReviewAnnotations from "./ManualProfileReviewAnnotations";

interface ManualProfileReviewStatusProps {
  userId: string;
}

const ManualProfileReviewStatus = ({
  userId,
}: ManualProfileReviewStatusProps) => {
  const snapshot = useFetchDocument<ManualProfileReview>({
    id: userId,
    collection: "manualProfileReviews",
  });

  if (snapshot.status === "loading") {
    return <div>Loading...</div>;
  }

  if (snapshot.status === "error") {
    return <div>Error fetching data</div>;
  }

  const manualProfileReview = snapshot.data as ManualProfileReview;

  const handleClick = async () => {
    const isReviewed = !manualProfileReview.reviewed;

    await ManualProfileReviewService.update(userId, {
      reviewed: isReviewed,
    });

    await AnnotationService.add(userId, {
      content: `Manual profile review set to ${
        isReviewed ? "completed" : "pending"
      }`,
      createdAt: Date.now(),
    });
  };

  if (!manualProfileReview) return <div>No manual review found</div>;

  return (
    <div className="flex items-center gap-2">
      <span>Manual verification: </span>
      <span
        className={`badge cursor-pointer ${
          manualProfileReview.reviewed ? "badge-success" : "badge-warning"
        }`}
        onClick={handleClick}
      >
        {manualProfileReview.reviewed ? "Completed" : "Pending"}
      </span>
      <span>
        <ManualProfileReviewAnnotations userId={userId} />
      </span>
    </div>
  );
};

export default ManualProfileReviewStatus;
