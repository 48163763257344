import { Route } from "react-router-dom";
import { KnockIndexPage, KnockShowPage } from "../pages/knocks";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";

export const KnockRoutes = (
  <>
    <Route
      path={routeNames["knocks.index.searching"]}
      element={
        <ProtectedRoute>
          <KnockIndexPage
            title="Searching requests"
            status={"searching"}
          />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["knocks.index.active"]}
      element={
        <ProtectedRoute>
          <KnockIndexPage
            title="Active requests"
            status={"active"}
          />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["knocks.index.expired"]}
      element={
        <ProtectedRoute>
          <KnockIndexPage
            title="Expired requests"
            status={"expired"}
          />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["knocks.index.closed"]}
      element={
        <ProtectedRoute>
          <KnockIndexPage
            title="Closed requests"
            status={"closed"}
          />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["knocks.index.all"]}
      element={
        <ProtectedRoute>
          <KnockIndexPage title="All requests" />
        </ProtectedRoute>
      }
    />

    <Route
      path={routeNames["knocks.show"](":id")}
      element={
        <ProtectedRoute>
          <KnockShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
