import { Route } from "react-router-dom";
import { ReferralDetailsPage, ReferralIndexPage } from "../pages/programs";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { ReferralEditPage } from "../pages/programs/ReferralEditPage";

export const ProgramsRoutes = (
  <>
    <Route
      path={routeNames["programs.index.referral"]}
      element={
        <ProtectedRoute>
          <ReferralIndexPage title="Referral Program" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["programs.edit.referral"]}
      element={
        <ProtectedRoute>
          <ReferralEditPage title="Referral Program" />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["programs.details.referral"](":id")}
      element={
        <ProtectedRoute>
          <ReferralDetailsPage />
        </ProtectedRoute>
      }
    />
  </>
);
