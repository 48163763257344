import { Route } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import {
  PromotionalBannerIndexPage,
  PromotionalBannerNewPage,
} from "../pages/promotionalBanners";
import { PromotionalBannerEditPage } from "../pages/promotionalBanners/PromotionalBannerEditPage";

export const PromotionalBannerRoutes = (
  <>
    <Route
      path={routeNames["promotionalBanners.index"]}
      element={
        <ProtectedRoute>
          <PromotionalBannerIndexPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["promotionalBanners.new"]}
      element={
        <ProtectedRoute>
          <PromotionalBannerNewPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["promotionalBanners.edit"](":id")}
      element={
        <ProtectedRoute>
          <PromotionalBannerEditPage />
        </ProtectedRoute>
      }
    />
  </>
);
