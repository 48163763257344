import {
  CollectionReference,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { GiftCardRequest } from "../types";

const ban = async (referralProgramProfileId: string) => {
  const ref = doc(db, "referralProgramProfiles", referralProgramProfileId);
  await updateDoc(ref, {
    bannedAt: Date.now(),
    isBanned: true,
    updatedAt: Date.now(),
  });

  const giftCardRequestsCollection = collection(
    db,
    "giftCardRequests"
  ) as CollectionReference<GiftCardRequest>;
  const giftCardRequestsQuery = query(
    giftCardRequestsCollection,
    where("userId", "==", referralProgramProfileId)
  );
  const giftCardRequestsSnap = await getDocs(giftCardRequestsQuery);

  for (const giftCardRequestDoc of giftCardRequestsSnap.docs) {
    await updateDoc(giftCardRequestDoc.ref, { isReferrerBanned: true });
  }
};

export const ReferralProgramProfileService = {
  ban,
};
