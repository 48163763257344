import { PageHeader } from "../../components/shared";
import { GiftCardRequestStatus } from "../../types";
import { GiftCardRequestsList } from "../../components/lists/GiftCardRequestsList";

type GiftCardRequestIndexPageProps = {
  status?: GiftCardRequestStatus;
  title: string;
};

export const GiftCardRequestIndexPage = ({
  status,
  title,
}: GiftCardRequestIndexPageProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8">
        <GiftCardRequestsList status={status} />
      </div>
    </>
  );
};
