import { KnocksTable } from "../../../components/knocks";
import { PageHeader } from "../../../components/shared";
import { KnockStatus } from "../../../types";

type KnockIndexPageProps = {
  status?: KnockStatus;
  title: string;
};

export const KnockIndexPage = ({
  status,
  title,
}: KnockIndexPageProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8">
        <KnocksTable status={status} />
      </div>
    </>
  );
};
