import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { PhoneVerificationShowPage } from "../pages/phoneVerifications/PhoneVerificationShowPage";

export const PhoneVerificationRoutes = (
  <>
    <Route
      path={routeNames["phone-verifications.show"](":phoneNumber")}
      element={
        <ProtectedRoute>
          <PhoneVerificationShowPage />
        </ProtectedRoute>
      }
    />
  </>
);
