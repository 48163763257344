import {
  Field,
  ErrorMessage,
  useField,
  FieldProps,
  FieldValidator,
  GenericFieldHTMLAttributes,
} from "formik";

type InputFieldProps = GenericFieldHTMLAttributes & {
  component?:
    | string
    | React.ComponentType<FieldProps<any>>
    | React.ComponentType
    | React.ForwardRefExoticComponent<any>;
  as?:
    | React.ComponentType<FieldProps<any>["field"]>
    | string
    | React.ComponentType
    | React.ForwardRefExoticComponent<any>;
  render?: (props: FieldProps<any>) => React.ReactNode;
  children?: ((props: FieldProps<any>) => React.ReactNode) | React.ReactNode;
  validate?: FieldValidator;
  name: string;
  type?: string;
  value?: any;
};

const InputField = ({ name, ...otherProps }: InputFieldProps) => {
  const [field, meta] = useField(name);
  const configTextField = {
    ...field,
    ...otherProps,
  };

  const renderError = (message: string) => (
    <p className="text-red-500">{message}</p>
  );

  if (meta && meta.touched && meta.error) {
  }
  return (
    <>
      <Field {...configTextField} />
      {meta && meta.touched && meta.error && (
        <ErrorMessage name="name" render={renderError} />
      )}
    </>
  );
};

export default InputField;
