import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AppNavbar } from "../components/layout/AppNavbar";
import { AppSidebar } from "../components/layout/AppSidebar";
import { FullPageLoader } from "../components/shared";
import { auth } from "../services/firebase";

type ProtectedRouteProps = {
  children: JSX.Element;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    } else {
      if (user === null) {
        navigate("/");
      } else {
        setIsAuthLoading(false);
      }
    }
  }, [user, loading, navigate]);

  return isAuthLoading ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="drawer drawer-mobile">
        <input id="drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content bg-slate-300">
          <AppNavbar user={user!} />
          <main className="flex-1 overflow-auto p-8 text-dark-blue">
            {children}
          </main>
        </div>
        <div className="drawer-side">
          <label htmlFor="drawer" className="drawer-overlay"></label>
          <AppSidebar />
        </div>
      </div>
    </>
  );
};
