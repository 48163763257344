import { useCallback } from "react";

import { db } from "../services/firebase";

import {
  where,
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  QueryConstraint,
} from "firebase/firestore";

const COLLECTION = "referralProgramProfiles";

export default function useFetchReferralProgramProfilesCount() {
  return useCallback(({ referredBy }: { referredBy?: string }) => {
    const collectionRef = collection(db, COLLECTION) as CollectionReference;

    const whereConstraints: QueryConstraint[] = [];

    if (referredBy) {
      whereConstraints.push(where("referredBy", "==", referredBy));
    } else {
      whereConstraints.push(where("referralsCount", ">", 0));
    }

    const constraints: QueryConstraint[] = [...whereConstraints];

    return getCountFromServer(query(collectionRef, ...constraints));
  }, []);
}
